import React, { useEffect, useState } from 'react';
import './Carousel.css';

const Carousel = ({
    ...props
}) => {
    let children = React.Children.toArray(props.children);

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (children?.length > 1){
            let timer = setInterval(() => slide(), 7000);

            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    const slide = (id) => {
        if (id)
            setActiveIndex(id);
        else
            setActiveIndex(prevState => (prevState + 1) % children.length);
    }

    return (
        <>
            <div className={`ui-carousel__container`}>
                {children?.map((t, i) =>
                    <div className={`ui-carousel__container__item ui-carousel__container__fade ${i === activeIndex ? 'active' : ''}`}>
                        {t}
                    </div>
                )}
                <div className={`ui-carousel__footer`}>
                {children && children.length > 1 && children.map((t, i) =>
                    <span
                        class={`ui-carousel__footer__dot ${i === activeIndex ? 'active' : ''}`}
                        onClick={() => slide(i)}
                    ></span>
                )}
                </div>
            </div>
        </>
    )
};

export default Carousel;