import { Modal } from "rsuite";
import "./Modal.css";

const SCModal = props => {
  return <Modal {...props}>{props.children}</Modal>;
};

const SCModalHeader = props => {
  return <Modal.Header {...props}>{props.children}</Modal.Header>;
};

const SCModalTitle = props => {
  return <Modal.Title {...props}>{props.children}</Modal.Title>;
};

const SCModalBody = props => {
  return <Modal.Body {...props}>{props.children}</Modal.Body>;
};

const SCModalFooter = props => {
  return (
    <Modal.Footer className="sc-modal__footer" {...props}>
      {props.children}
    </Modal.Footer>
  );
};

SCModal.Header = SCModalHeader;
SCModal.Title = SCModalTitle;
SCModal.Body = SCModalBody;
SCModal.Footer = SCModalFooter;
SCModal.Title = SCModalTitle;

export default SCModal;
