import { useState, useEffect } from "react";
import { Page } from "@/Components/Layout";
import {
  Tooltip,
  Table,
  Dropdown,
  Icon,
  Button,
  Popover,
  Badge,
} from "@/Components/UI";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ticketingService, chatService } from "@/Services";
import { pagingService } from "@/Services/PagingService";
import { useTickets } from "@/Hooks";
import { formatTimeAgo, formatUtcToDateAndTime } from "@/Helpers/DateHelper";
import TicketingFilterPopup from "./TicketingFilterPopup";
import StatusBadge from "@/Components/StatusBadge";
import "./Ticketing.css";
import OpenTicketEditor from "./OpenTicketEditor";
import { Permission } from "../../Components/UI";
import { OidcConfig } from "../../Config/OidcConfig";

const Ticketing = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const SEARCH_KEY = "ticketing-search";

  const getSearch = () => {
    return (
      (sessionStorage.getItem(SEARCH_KEY) &&
        JSON.parse(sessionStorage.getItem(SEARCH_KEY))) ||
      null
    );
  };

  const storeSearch = () => {
    const storedSearch = getSearch();
    const newSearch = {
      ...storedSearch,
      page,
      pageSize,
      filterValues,
      ticketPriority,
      ticketStatus,
      ticketSort,
    };
    sessionStorage.setItem(SEARCH_KEY, JSON.stringify(newSearch));
  };

  const searchData = getSearch();
  const sortingOptions = [
    { value: "-modifiedAt", label: "Last activity" },
    { value: "-createdAt", label: "Newest" },
    { value: "+createdAt", label: "Oldest" },
    { value: "+priorityId,-createdAt", label: "Priority (high to low)" },
    { value: "+statusId,-createdAt", label: "Status (created to closed)" },
  ];

  const [page, setPage] = useState(searchData?.page || 1);
  const [pageSize, setPageSize] = useState(searchData?.pageSize || 10);

  const [ticketPriority, setTicketPriority] = useState(
    searchData?.ticketPriority || null,
  );
  const [ticketStatus, setTicketStatus] = useState(
    searchData?.ticketStatus || null,
  );
  const [ticketSort, setTicketSort] = useState(
    searchData?.ticketSort || "-modifiedAt",
  );

  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [filterValues, setFilterValues] = useState(
    searchData?.filterValues || null,
  );
  const [countFilterValues, setCountFilterValues] = useState(0);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [showOpenTicket, setShowOpenTicket] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({});

  const {
    data: tickets,
    loading,
    mutate,
  } = useTickets({
    page,
    take: pageSize,
    priorityId: ticketPriority,
    statusId: ticketStatus,
    sort: ticketSort,
    ...filterValues,
    // isContactCenter: filterValues?.isContactCenter,
  });

  const toggleFilterPopup = () => {
    setOpenFilterPopup(prevState => !prevState);
  };

  useEffect(() => {
    const fetchData = async () => {
      const [statusOptionsRes, pageSizeOptions, priorityOptionsRes] =
        await Promise.all([
          ticketingService.getTicketingStatuses(),
          pagingService.getPageSizeOptions(),
          ticketingService.getTicketingPriorities(),
          ticketingService.getTicketingCategories(),
        ]);

      setStatusOptions((statusOptionsRes && statusOptionsRes.data) || []);
      setPageSizeOptions(pageSizeOptions);
      setPriorityOptions((priorityOptionsRes && priorityOptionsRes.data) || []);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (filterValues) {
      let c = 0;
      Object.keys(filterValues).forEach(key => {
        if (filterValues[key]?.length > 0 || filterValues[key] === true) c++;
      });
      setCountFilterValues(c);
    } else {
      setCountFilterValues(0);
    }
  }, [filterValues]);

  useEffect(() => {
    const fetchData = async () => {
      if (tickets && tickets.results && tickets.results.length) {
        const responses = await Promise.all(
          tickets.results.map(t => chatService.getUnreadMessages(t.id)),
        );
        if (responses) {
          let unreadCount = {};
          tickets.results.forEach((t, i) => {
            unreadCount[t.id] = responses[i].data;
          });

          setUnreadMessages(unreadCount);
        }
      }
    };
    fetchData();
  }, [tickets]);

  useEffect(() => {
    storeSearch();
  }, [page, pageSize, ticketStatus, filterValues, ticketPriority, ticketSort]);

  const handleChangePage = page => {
    setPage(page);
  };

  const handleChangePageSize = pageSize => {
    setPageSize(pageSize);
  };

  const handleChangeStatus = status => {
    setPage(1);
    setTicketStatus(status);
  };

  const handleChangeSort = sort => {
    setPage(1);
    setTicketSort(sort);
  };

  const handleChangePriority = priority => {
    setPage(1);
    setTicketPriority(priority);
  };

  const handleRowClick = data => {
    history.push("/ticketing/" + data.id);
  };

  const handleFilterClick = filter => {
    setFilterValues(filter);
    toggleFilterPopup();
    setPage(1);
  };

  const handleFilterClearClick = () => {
    setFilterValues();
    toggleFilterPopup();
    setPage(1);
  };

  const columns = [
    {
      title: t("Title"),
      key: "title",
      CustomCell: ({ value, rowData }) => (
        <div className="ticket__title">
          <Badge
            maxCount={10}
            content={(unreadMessages && unreadMessages[rowData.id]) || false}
            tooltip={t("Unread messages")}>
            <h3 className="sc-post__title">{value}</h3>
          </Badge>
          <div className="sc-post__meta">
            {t("Created by")} <strong>{rowData["createdByName"]}</strong> •{" "}
            <Tooltip
              placement={"topLeft"}
              text={formatUtcToDateAndTime(rowData["createdAt"])}>
              <span className="sc-post__meta">
                {formatTimeAgo(t, rowData["createdAt"])}
              </span>
            </Tooltip>
          </div>
        </div>
      ),
      width: 450,
      resizable: true,
      wordWrap: true,
    },
    {
      title: t("Category"),
      key: "topicName",
      width: 150,
      resizable: true,
      wordWrap: true,
    },
    {
      title: t("Assigned to"),
      key: "assignedToDisplayName",
      width: 250,
      resizable: true,
      wordWrap: true,
      CustomCell: ({ value, rowData }) => (
        <>{value?.trim() || t("Unassigned")}</>
      ),
      /*
            CustomCell: ({value, rowData}) => (
                <AssignablePicker 
                    className="sc-picker-disabled"
                    disabled
                    appearance="subtle"
                    value={rowData["assignedToId"]}
                    cleanable={false}
                />
            )*/
    },
    {
      title: t("Priority"),
      key: "priorityName",
      width: 130,
      resizable: true,
      wordWrap: true,
      CustomCell: ({ value, rowData }) => (
        <StatusBadge
          txtContent={value}
          color={
            ((value == "High" || value == "Very High") && "red") ||
            ((value == "Low" || value == "Very Low") && "green") ||
            "yellow"
          }
        />
      ),
      align: "left",
    },
    {
      title: t("Status"),
      key: "statusName",
      width: 160,
      CustomCell: ({ value, rowData }) => (
        <span
          className={`ticket__cell-status${
            (rowData["closed"] && " ticket__cell-status--closed") || ""
          }`}>
          {t(value)}
        </span>
      ),
      resizable: true,
      wordWrap: true,
      align: "left",
    },
  ];

  const toggleOpenTicket = () => {
    setShowOpenTicket(!showOpenTicket);
  };

  const handleOpenTicket = () => {
    toggleOpenTicket();
  };

  const handleSubmitOpenTicket = () => {
    mutate();
  };

  return (
    <Page>
      <Page.Header>
        <Popover
          title={t("Filter options")}
          trigger="none"
          open={openFilterPopup}
          placement={"bottomEnd"}
          content={
            <TicketingFilterPopup
              onClickAway={() => setOpenFilterPopup(false)}
              onClear={handleFilterClearClick}
              onSubmit={handleFilterClick}
              value={filterValues}
            />
          }>
          <Button
            onClick={toggleFilterPopup}
            className={countFilterValues > 0 ? "sc-btn--trigger" : ""}>
            <Icon icon="filter" /> &nbsp; {t("Filter")}{" "}
            {countFilterValues > 0 ? `(${countFilterValues})` : ""}
          </Button>
        </Popover>

        {/*<Dropdown title={t("All categories")} options={categoryOptions} labelKey="name" valueKey="id" onSelect={handleChangeCategory} />*/}
        <Dropdown
          hasDefaultOption
          defaultLabel="All statuses"
          title={
            (searchData &&
              searchData.ticketStatus &&
              t(
                statusOptions &&
                  statusOptions.find(o => o.id == searchData?.ticketStatus)
                    ?.name,
              )) ||
            t("All statuses")
          }
          options={statusOptions}
          labelKey="name"
          valueKey="id"
          onSelect={handleChangeStatus}
        />
        <Dropdown
          hasDefaultOption
          defaultLabel="All priorities"
          title={
            (searchData &&
              searchData.ticketPriority &&
              t(
                priorityOptions.find(o => o.id == searchData?.ticketPriority)
                  ?.name,
              )) ||
            t("All priorities")
          }
          options={priorityOptions}
          labelKey="name"
          valueKey="id"
          onSelect={handleChangePriority}
        />
        <Dropdown
          title={
            (searchData &&
              searchData.ticketSort &&
              t(
                sortingOptions.find(o => o.value == searchData?.ticketSort)
                  ?.label,
              )) ||
            t("Last activity")
          }
          defaultLabel="Last activity"
          onSelect={handleChangeSort}
          labelKey="label"
          valueKey="value"
          options={sortingOptions}
        />

        <Permission claims={[`${OidcConfig.client_id}.ticketing.*`]}>
          <Button appearance="primary" onClick={handleOpenTicket}>
            {t("Open ticket")}
          </Button>
        </Permission>
      </Page.Header>

      <Table
        data={(tickets && tickets.results) || []}
        isLoading={loading}
        pagination={true}
        rowKey="id"
        totalItems={(tickets && tickets.total) || 0}
        page={page}
        pageSize={pageSize}
        pageSizeMenu={pageSizeOptions}
        columns={columns}
        rowHeight={66}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        onRowClick={handleRowClick}
      />

      {showOpenTicket && (
        <OpenTicketEditor
          show={showOpenTicket}
          onClose={toggleOpenTicket}
          onSubmit={handleSubmitOpenTicket}
        />
      )}
    </Page>
  );
};

export default Ticketing;
