export const toQueryString = (params) => {
    let query = {...params};
    for (let param in query) { /* You can get copy by spreading {...query} */
        if (query[param] === undefined /* In case of undefined assignment */
            || query[param] === null 
            || query[param] === ""
        ) {    
            delete query[param];
        }
    }

    const usp = new URLSearchParams(query);

    // Create a stable key for SWR
    usp.sort();
    const qs = usp.toString();
    return qs;
}