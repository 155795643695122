import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./OrgChart.css";

const Card = (props) => {
  return (
    <ul className={`${props.root && "root" || ''}`}>
      {props && props.data && props.data.map((item) => (
          <li className="leaf">
            <div className={`nodecontent ${item && item.type || ''}`}>{item.name}</div>
            {item.children?.length && <Card data={item.children} root={false} /> || null}
          </li>
      ))}
    </ul>
  );
};

const OrgChart = (props) => {
  return (
    <div className="orgchart__container">
        <TransformWrapper       
            limitToBounds={false} 
        >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
                <div className="orgchart__tools">
                <button onClick={() => zoomIn()}>+</button>
                <button onClick={() => zoomOut()}>-</button>
                <button onClick={() => resetTransform()}>x</button>
                </div>
                <TransformComponent>
                    <figure class="orgchart">
                        <Card data={props.data} root={true} />
                    </figure>
                </TransformComponent>
            </>)}
        </TransformWrapper>
    </div>
  );
};

export default OrgChart;