import React from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Group } from "@visx/group";
import { RadialGradient } from "@visx/gradient";
import browserUsage from "@visx/mock-data/lib/mocks/browserUsage";
import "../Payments.css";
import { formatCurrency } from "@/Helpers/NumberHelper";
import { useTranslation } from "react-i18next";

const browserNames = Object.keys(browserUsage[0]).filter(k => k !== "date");

// accessor functions
const usage = d => d.usage;

// color scales
const getBrowserColor = scaleOrdinal({
  domain: browserNames,
  range: [
    "rgba(255,255,255,0.7)",
    "rgba(255,255,255,0.6)",
    "rgba(255,255,255,0.5)",
    "rgba(255,255,255,0.4)",
    "rgba(255,255,255,0.3)",
    "rgba(255,255,255,0.2)",
    "rgba(255,255,255,0.1)",
  ],
});

const PaymentsPieChart = ({ width, height, margin, data, total }) => {
  const { t } = useTranslation();
  let today = new Date();
  let lastDayOfDualCurrency = new Date("2023-01-01");

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;

  return (
    <div style={{ width, height }}>
      <svg width={width} height={height} className="smooth-border">
        {/* <GradientPinkBlue id="teal" /> */}
        <RadialGradient id="radial" from="#55bdd5" to="#2d82a1" r="80%" />
        <rect width={width} height={height} fill="url(#radial)" rx={3} />
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          {data && (
            <Pie
              data={data}
              pieValue={usage}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={7}
              padAngle={0.005}>
              {pie => {
                return pie.arcs.map((arc, index) => {
                  const { label, usage } = arc.data;
                  const [centroidX, centroidY] = pie.path.centroid(arc);
                  const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                  const arcPath = pie.path(arc);
                  const arcFill = getBrowserColor(usage);
                  return (
                    <g key={`arc-${usage}-${index}`}>
                      <path d={arcPath} fill={arcFill} />
                      {hasSpaceForLabel && (
                        <text
                          x={centroidX}
                          y={centroidY - 10}
                          dy=".20em"
                          fill="#073763"
                          fontSize={10}
                          fontWeight={400}
                          textAnchor="middle"
                          pointerEvents="none">
                          {label}
                        </text>
                      )}
                      {hasSpaceForLabel && (
                        <text
                          x={centroidX}
                          y={centroidY + 5}
                          dy=".20em"
                          fill="#073763"
                          fontSize={10}
                          fontWeight={400}
                          textAnchor="middle"
                          pointerEvents="none">
                          {`${formatCurrency(usage) + " EUR"}`}
                        </text>
                      )}
                    </g>
                  );
                });
              }}
            </Pie>
          )}

          <text
            textAnchor="middle"
            fill="#1a364e"
            fontSize={15}
            fontWeight={600}
            pointerEvents="none">
            {t("Total services")}
          </text>
          <text
            textAnchor="middle"
            fill="#1a364e"
            fontSize={15}
            fontWeight={600}
            pointerEvents="none"
            dy="1.3em">
            {`${formatCurrency(total) + " EUR"}`}
          </text>
        </Group>
        <text
          textAnchor="middle"
          x={centerX + margin.left}
          y={margin.top - 25}
          fill="#ffffff"
          fontSize={18}
          dy="1.3em"
          pointerEvents="none">
          {t("Data for selected date range")}
        </text>
      </svg>
    </div>
  );
};

export default PaymentsPieChart;
