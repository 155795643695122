import { useState, useEffect } from "react";
import { RadioGroup, Radio, DatePicker, Grid, Row, Col } from "rsuite";
import "./PublishSettings.css";
import { Button } from "@/Components/UI";
import { useTranslation } from "react-i18next";
import {
  getYear,
  getMonth,
  getDate,
  getHours,
  getMinutes,
  addMinutes,
  isBefore,
} from "date-fns";
import { Checkbox } from "../../Components/UI";

const PublishSettings = ({ title, onCancel, onPublish }) => {
  const PUBLISH = 1;
  const SCHEDULE = 2;

  const { t } = useTranslation();
  const [publishType, setPublishType] = useState(1);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [sendNotification, setSendNotification] = useState(false);

  const [dateDefaultValue, setDateDefaultValue] = useState(null);
  const [timeDefaultValue, setTimeDefaultValue] = useState(null);

  const handlePublishTypeChange = value => {
    setPublishType(value);
  };

  useEffect(() => {
    if (publishType == 1) {
      setDateDefaultValue(null);
      setTimeDefaultValue(null);
    } else if (publishType == 2) {
      let now = new Date();
      setDateDefaultValue(now);
      setTimeDefaultValue(addMinutes(now, 5));
      setDate(now);
      setTime(addMinutes(now, 5));
    }
  }, [publishType]);

  const handleDateChange = date => {
    setDate(date);
    setDateDefaultValue(date);
  };

  const handleTimeChange = time => {
    setTime(time);
    setTimeDefaultValue(time);
  };

  const handleSchedulePublish = () => {
    let publishDate = new Date().toISOString();
    let sendNotificationValue = sendNotification;

    if (publishType != PUBLISH) {
      sendNotificationValue = false;
      publishDate = new Date(
        getYear(date),
        getMonth(date),
        getDate(date),
        getHours(time),
        getMinutes(time),
        0,
      ).toISOString();
      setDate(null);
      setTime(null);
    }

    onPublish(publishDate, publishType, sendNotificationValue);
  };

  return (
    <div className="sc-post-publish-settings">
      <header className="sc-post-publish-settings__heading">{title}</header>
      <div className="sc-post-publish-settings__content">
        <section className="sc-post-publish-settings__section">
          <Grid fluid>
            <Row>
              <RadioGroup
                name="publishType"
                className="sc-post-publish-settings__picker"
                value={publishType}
                onChange={value => {
                  handlePublishTypeChange(value);
                }}>
                <Radio
                  value={PUBLISH}
                  className="sc-post-publish-settings__live">
                  <div className="sc-post-publish-settings__option">
                    {t("Set it live now")}
                  </div>
                  <div className="sc-post-publish-settings__description">
                    {t("Publish this post immediately")}
                  </div>
                  {publishType === PUBLISH && (
                    <Checkbox
                      value={sendNotification}
                      onChange={e => {
                        setSendNotification(x => !x);
                      }}>
                      {t("Send notification")}
                    </Checkbox>
                  )}
                </Radio>
                <Radio
                  value={SCHEDULE}
                  className="sc-publish-settings__schedule">
                  <div className="sc-post-publish-settings__option">
                    {t("Schedule it for later")}
                  </div>
                </Radio>
              </RadioGroup>
            </Row>
            <Row className="sc-post-publish-settings__datepicker">
              <Col xs={12} className="sc-post-publish-settings__col">
                <DatePicker
                  format="DD.MM.YYYY"
                  block
                  className="sc-publish-settings__date"
                  onChange={date => handleDateChange(date)}
                  value={dateDefaultValue}
                  disabledDate={date => isBefore(date, new Date())}
                />
              </Col>
              <Col xs={12}>
                <DatePicker
                  format="HH:mm"
                  block
                  className="sc-publish-settings__time"
                  onChange={time => handleTimeChange(time)}
                  value={timeDefaultValue}
                />
              </Col>
              <Col
                xs={24}
                className="sc-post-publish-settings__description sc-post-publish-settings__col">
                {t("Set automatic future publish date")}
              </Col>
            </Row>
          </Grid>
        </section>
      </div>

      <footer className="sc-post-publish-settings__footer">
        <Button className="sc-btn sc-btn--outline" onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button appearance="primary" onClick={handleSchedulePublish}>
          {t((publishType == SCHEDULE && "Schedule") || "Publish")}
        </Button>
      </footer>
    </div>
  );
};

export default PublishSettings;
