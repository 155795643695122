import hrHR from 'date-fns/locale/hr';

/*
var Calendar = {
  sunday: 'Su',
  monday: 'Mo',
  tuesday: 'Tu',
  wednesday: 'We',
  thursday: 'Th',
  friday: 'Fr',
  saturday: 'Sa',
  ok: 'OK',
  today: 'Today',
  yesterday: 'Yesterday',
  hours: 'Hours',
  minutes: 'Minutes',
  seconds: 'Seconds',
  formattedMonthPattern: 'MMM, YYYY',
  formattedDayPattern: 'MMM DD, YYYY'
};
var _default = {
  Pagination: {
    more: 'More',
    prev: 'Previous',
    next: 'Next',
    first: 'First',
    last: 'Last'
  },
  Table: {
    emptyMessage: 'No data found',
    loading: 'Loading...'
  },
  TablePagination: {
    lengthMenuInfo: '{0} / page',
    totalInfo: 'Total: {0}'
  },
  Calendar: Calendar,
  DatePicker: (0, _extends2.default)({}, Calendar),
  DateRangePicker: (0, _extends2.default)({}, Calendar, {
    last7Days: 'Last 7 Days'
  }),
  Picker: {
    noResultsText: 'No results found',
    placeholder: 'Select',
    searchPlaceholder: 'Search',
    checkAll: 'All'
  },
  InputPicker: {
    newItem: 'New item',
    createOption: 'Create option "{0}"'
  },
  Uploader: {
    inited: 'Initial',
    progress: 'Uploading',
    error: 'Error',
    complete: 'Finished',
    emptyFile: 'Empty',
    upload: 'Upload'
  }
};
*/

const Calendar = {
  sunday: 'Ned',
  monday: 'Pon',
  tuesday: 'Uto',
  wednesday: 'Sri',
  thursday: 'Čet',
  friday: 'Pet',
  saturday: 'Sub',
  ok: 'OK',
  today: 'Danas',
  yesterday: 'Jučer',
  hours: 'Sati',
  minutes: 'Minuta',
  seconds: 'Sekundi',
  formattedMonthPattern: 'MMM, yyyy',
  formattedDayPattern: 'dd MMM, yyyy',
  dateLocale: hrHR 
};

export default {
  common: {
    loading: 'Učitavanje...',
    emptyMessage: 'Nema podataka'
  },
  Plaintext: {
    unfilled: 'Neispunjeno',
    notSelected: 'Nije odabrano',
    notUploaded: 'Nije učitano'
  },
  Pagination: {
    more: 'Više',
    prev: 'Prethodno',
    next: 'Sljedeće',
    first: 'Prva',
    last: 'Zadnja',
    limit: '{0} / stranica',
    total: 'Ukupno redaka: {0}',
    skip: 'Skoči na{0}'
  },
  TablePagination: {
    lengthMenuInfo: '{0} / stranica',
    totalInfo: 'Ukupno: {0}'
  },
  Calendar,
  DatePicker: {
    ...Calendar
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: 'Zadnjih 7 Dana'
  },
  Picker: {
    noResultsText: 'Nema rezultata',
    placeholder: 'Odaberi',
    searchPlaceholder: 'Pretraga',
    checkAll: 'Sve'
  },
  InputPicker: {
    newItem: 'Novi zapis',
    createOption: 'Stvori novi zapis "{0}"'
  },
  Uploader: {
    inited: 'Početak',
    progress: 'Učitavanje',
    error: 'Greška',
    complete: 'Završeno',
    emptyFile: 'Prazno',
    upload: 'Učitaj'
  },
  CloseButton: {
    closeLabel: 'Zatvori'
  },
  Breadcrumb: {
    expandText: 'Prikaži putanju'
  },
  Toggle: {
    on: 'Otvori',
    off: 'Zatvori'
  }
};