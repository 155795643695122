import { OidcConfig } from "@/Config/OidcConfig";

export const hasAuth = (user, roles, claims, path) => {
  const appId = OidcConfig.client_id;

  const userClaims =
    (user && [...Object.keys(user).filter((k) => k.includes(appId))]) || null;

  const checkClaims =
    (claims &&
      claims.length &&
      claims.flatMap((c) => {
        const parts = c.split(".");
        return [c, `${parts.slice(0, -1).join(".")}.*`];
      })) ||
    null;

  console.log();

  if (user && userClaims && !userClaims.some((key) => key.includes(appId))) {
    console.error("User has no authorizations");
    sessionStorage.removeItem("initialLocation");
    return false;
  }

  if (userClaims && userClaims.some((key) => key == `${appId}.*`)) {
    return true;
  }

  const hasRoles =
    (user &&
      roles &&
      roles.length &&
      user.roles &&
      user.roles.length &&
      user.roles.some((role) => roles.includes(role))) ||
    false;
  const hasClaims =
    (user &&
      checkClaims &&
      checkClaims.length &&
      userClaims.some((key) => checkClaims.includes(key))) ||
    false;
  const onlyAuthorized =
    (user &&
      (!roles || (roles && !roles.length)) &&
      (!claims || (claims && !claims.length))) ||
    false;

  return onlyAuthorized || hasClaims || hasRoles;
};
