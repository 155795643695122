import React, { useContext, useState } from "react";
import { Panel, Modal } from "rsuite";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { formatUtcToDateAndTime } from "@/Helpers/DateHelper";
import { Button, ModalConfirm } from "@/Components/UI";
import StatusBadge from "@/Components/StatusBadge";
import { PostStatuses } from "@/Constants/Posts/PostStatuses";
import PublishSettings from "@/Pages/Posts/PublishSettings";
import { postService } from "@/Services/PostService";
import "./Home.css";
import { useHistory, useLocation } from "react-router-dom";
import { Permission } from "../../Components/UI";
import { OidcConfig } from "../../Config/OidcConfig";

export const ExternalPosts = ({ posts, onChange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { openSuccessModal, openErrorModal } = useContext(GlobalContext);

  const [isSchedulePopupOpened, setIsSchedulePopupOpened] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState();
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);

  const handleCancelSchedulePopup = () => {
    setIsSchedulePopupOpened(false);
  };

  const handleSchedulePublish = async (publishDate, postId) => {
    setIsSchedulePopupOpened(false);
    await postService
      .publishPost(postId, publishDate)
      .then(res => {
        openSuccessModal({ title: t("Post publish date set successfully") });
        onChange();
      })
      .catch(errMsg => {
        openErrorModal({ title: t("Error setting publish date for post") });
        console.log("Error message while trying to publish post:", errMsg);
      });
  };

  const handlePublishButtonClick = (statusCode, postId) => {
    setSelectedPostId(postId);
    if (statusCode === PostStatuses.Draft) {
      setIsSchedulePopupOpened(true);
    } else {
      setShowUnpublishModal(true);
    }
  };

  const handleCloseUnpublishModal = () => {
    setShowUnpublishModal(false);
  };

  const handleUnpublishModal = async postId => {
    setShowUnpublishModal(false);
    await postService
      .unpublishPost(postId)
      .then(res => {
        openSuccessModal({ title: t("Post unpublished successfully") });
      })
      .catch(errMsg => {
        openErrorModal({ title: t("Error unpublishing post") });
        console.log(
          "Error message while trying to unpublish this post:",
          errMsg,
        );
      });
  };

  const handleClickExternalPost = id => () => {
    history.push({
      pathname: "/posts/editor/" + id,
      state: {
        from: location.pathname,
      },
    });
  };

  return (
    <>
      <div className="box-container bordered">
        <div className="title">{t("Vijesti vanjskih poduzeća")}</div>
        {posts && (
          <div className="table-like-container">
            <div className="row home-external-post header">
              <div>{t("Naslov")}</div>
              <Permission claims={[`${OidcConfig.client_id}.posts.edit`]}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}>
                  {t("Status")}
                </div>
              </Permission>
            </div>
            {posts.length > 0 &&
              posts.map(p => (
                <>
                  <div className="row home-external-post">
                    <div>
                      <h3
                        className="sc-post__title"
                        onClick={handleClickExternalPost(p?.id)}>
                        {p?.title}
                      </h3>
                      <span className="sc-post__meta">
                        {t("Written by")}
                        <strong>{p?.primaryAuthor}</strong>
                        &nbsp;•&nbsp;
                        <strong>
                          {formatUtcToDateAndTime(
                            p?.publishedAt || p?.createdAt,
                          )}
                        </strong>
                      </span>
                    </div>{" "}
                    <Permission claims={[`${OidcConfig.client_id}.posts.edit`]}>
                      <div
                        className="align-right"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}>
                        <Button
                          appearance={
                            (p.statusCode === PostStatuses.Draft &&
                              "primary") ||
                            "default"
                          }
                          onClick={() =>
                            handlePublishButtonClick(p.statusCode, p.id)
                          }>
                          {p.statusCode === PostStatuses.Draft
                            ? t("Publish")
                            : t("Unpublish")}
                        </Button>
                      </div>
                    </Permission>
                  </div>
                </>
              ))}
            {posts.length === 0 && (
              <div className="row align-center">{t("No data found")}</div>
            )}
          </div>
        )}
      </div>
      <Modal show={isSchedulePopupOpened}>
        <PublishSettings
          title={t("Ready to publish your post?")}
          onCancel={handleCancelSchedulePopup}
          onPublish={publishDate =>
            handleSchedulePublish(publishDate, selectedPostId)
          }
        />
      </Modal>

      <ModalConfirm
        showModal={showUnpublishModal}
        closeModal={handleCloseUnpublishModal}
        onClickOk={() => handleUnpublishModal(selectedPostId)}
        textModal={t("Do you really want to unpublish this post?")}
      />
    </>
  );
};

export default ExternalPosts;
