import useSWR from 'swr';
import { usersApiUrl, alphaIdApiUserUrl, cmsApiUrl } from '@/Config/Url';

export const useUser = (id) => {
    const { data, error, mutate } = useSWR(`${alphaIdApiUserUrl}/id/${id}`);

    return {
        loading: !error && !data,
        data,
        error,
        mutate
    };
}

export const useUsers = (page, take, searchText) => {
    const skip = (page - 1) * take;
    const searchParamQuery = searchText && `?searchParam=${searchText}` || '';
    return useSWR(`${alphaIdApiUserUrl}/${skip}/${take}${searchParamQuery}`);
}

export const useUserGroups = (filter) => {
    return useSWR(`${cmsApiUrl}/groups?filter=${filter}`);
}

const useDashboard = () => {
    return useSWR(`${usersApiUrl}/dashboard`);
}

export default {
    useUser,
    useUsers,
    useUserGroups,
    useDashboard
}
