export const euroToHrkRate = 7.5345;

class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210,
      ),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find(d => d.type === "group").value}]`,
      "g",
    );
    this._decimal = new RegExp(
      `[${parts.find(d => d.type === "decimal").value}]`,
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d);
  }
  parse(string) {
    const str =
      (string &&
        isNaN(string) &&
        typeof value == "string" &&
        string
          .trim()
          .replace(this._group, "")
          .replace(this._decimal, ".")
          .replace(this._numeral, this._index)) ||
      string;
    return str ? +str : NaN;
  }
}

export const parseNumberLocale = (value, locale) => {
  const finalLocale = "hr";
  if (isNaN) {
    return new NumberParser(finalLocale).parse(value);
  }

  return value;
};

export const formatCurrency = (value, locale) => {
  const selectedLocale = "hr";
  const formatter = new Intl.NumberFormat(selectedLocale, {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const strVal = (value && value.toString()) || "0";
  const numberVal = parseNumberLocale(strVal, selectedLocale);
  const formattedVal = formatter.format(numberVal);
  return formattedVal;
};

export const formatCurrencyEuro = (value, locale, conversionValue) => {
  const selectedLocale = "hr";
  let selectedConversionValue = conversionValue || euroToHrkRate;
  const formatter = new Intl.NumberFormat(selectedLocale, {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let convertedValue = value / selectedConversionValue;
  const strVal = (convertedValue && convertedValue.toString()) || "0";
  const numberVal = parseNumberLocale(strVal, selectedLocale);
  const formattedVal = formatter.format(numberVal);
  return formattedVal;
};

export const formatNumber = (value, locale) => {
  const selectedLocale = "hr";
  const formatter = new Intl.NumberFormat(selectedLocale, {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const strVal = (value && value.toString()) || "0";
  const numberVal = parseNumberLocale(strVal, selectedLocale);
  const formattedVal = formatter.format(numberVal);
  return formattedVal;
};

export const localCurrencyToAlternativeCurrency = value => {
  return new Date().getFullYear() >= 2023
    ? value * euroToHrkRate
    : value / euroToHrkRate;
};
