import useSWR from "swr";
import { formApiUrl } from "@/Config/Url";

const useDashboard = () => {
  return useSWR(`${formApiUrl}/statistics/dashboard`);
};

export const useCategories = () => {
  const { data, error, mutate } = useSWR(`${formApiUrl}/categories`);
  return {
    loading: !error && !data,
    data: data,
    error,
    mutate,
  };
};

export const useCategoriesStatuses = () => {
  const { data, error, mutate } = useSWR(`${formApiUrl}/categories/statuses`);
  return {
    loading: !error && !data,
    data,
    error,
    mutate,
  };
};

export const useFormStatuses = () => {
  const { data, error, mutate } = useSWR(`${formApiUrl}/forms/statuses`);
  return {
    loading: !error && !data,
    data,
    error,
    mutate,
  };
};

export default {
  useDashboard,
  useCategories,
};
