import { Avatar } from "rsuite";

const SCAvatar = ({ user, size, circle, ...props }) => {
  const name = user && (user.displayName || user.name);
  const userInitials =
    (name &&
      `${name.split(" ")[0][0]?.toUpperCase()}${name
        .split(" ")[1][0]
        ?.toUpperCase()}`) ||
    "NN";

  return (
    (user && user.avatar && (
      <Avatar
        size={size}
        circle={circle}
        src={user.avatar || null}
        alt={userInitials}
        {...props}
      />
    )) || (
      <Avatar size={size} circle={circle} {...props}>
        {userInitials}
      </Avatar>
    )
  );
};

export default SCAvatar;
