import { forwardRef } from 'react';
import { Popover, Whisper } from 'rsuite';

const SCPopover = forwardRef(({title, content, style, onClickAway, ...props}, ref) => {
    return (
        <Whisper
            {...props}
            speaker={
                <Popover style={style} title={title}>
                   {content} 
                </Popover>
            }
        >
            {props.children}
        </Whisper>
    );
})

export default SCPopover;