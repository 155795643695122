import { forwardRef, useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import { languageService } from "../../Services/LanguageService";

const LanguagePicker = forwardRef(
  ({ value, onChange, valueKey = "cultureCode", ...props }, ref) => {
    const [languages, setLangugaes] = useState();

    useEffect(() => {
      languageService.getLanguages().then((res) => {
        const result = res?.data?.results || [];
        setLangugaes(
          result.map((x) => ({
            value: String(x[valueKey]),
            label: x.name,
            flag: x.cultureCode,
          }))
        );
      });
    }, []);

    return (
      <SelectPicker
        block
        ref={ref}
        value={value}
        data={languages}
        // placeholder={t("Croatian")}
        searchable={false}
        cleanable={false}
        renderMenuItem={(label, item) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="language-picker-item"
                style={{ flexGrow: 1, marginRight: "1rem" }}
              >
                <span
                  className={`flag-icon flag-icon-${item?.flag}`}
                  style={{ marginRight: "0.5rem" }}
                ></span>{" "}
                {label}
              </div>
            </div>
          );
        }}
        renderValue={(value, item) => {
          return (
            <div className="language-picker-item">
              <span
                className={`flag-icon flag-icon-${item?.flag}`}
                style={{ marginRight: "0.5rem" }}
              ></span>{" "}
              {item?.label}
            </div>
          );
        }}
        onChange={onChange}
        {...props}
      />
    );
  }
);

export default LanguagePicker;
