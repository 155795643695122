import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { oidcClientService } from "@/Services/OidcClientService";
import { loginUrl } from "@/Config/Url";
import Loader from "@/Components/Loader";

function SignoutOidc() {
  const history = useHistory();

  useEffect(() => {
    async function signoutAsync() {
      sessionStorage.removeItem(`oidc.user:${loginUrl}:sc-admin-web`);
      await oidcClientService.signOutRedirectCallback();
      history.push("/");
    }
    signoutAsync();
  }, []);

  return <Loader />;
}

export default SignoutOidc;
