import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectPicker, Icon } from "@/Components/UI";
import "./VendorPicker.css";
import debounce from 'lodash.debounce';
import {invoiceService} from "@/Services"
import { GlobalContext } from "@/Contexts/GlobalContext";


const VendorPicker = ({pageSize, value, display, ...props}) => {

    //const { setLoading, errorHandler } = useContext(GlobalContext);
    const [vendors, setVendors] = useState([]);
    const[loading, setLoading] = useState();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();

    useEffect(() => {
        setLoading(false);
        getVendorData();
        console.log("Got vendor id:", value)
    }, [])

    const getVendorData = async () => {
        setLoading(true);
        let response = await invoiceService.getVendors();
        setVendors(response.data.results);
        setLoading(false);
        } 

    const handleSearch = useMemo(
        () => debounce(setSearch, 400)
      , []);

    return (
        <SelectPicker 
            className="sc-vendor-picker"
            valueKey="externalId" 
            cleanable={false}
            labelKey="name" 
            data={vendors}
            onSearch={handleSearch}
            placeholder={t("Unassigned")}
            value={value}
            renderMenu={menu => {
                if (loading) {
                    return (
                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                            <Icon icon="circle-o-notch" spin /> {t("Loading")}...
                        </p>
                    );
                }
                return menu;
            }}
            {...props} 
        />
    );
};

export default VendorPicker;