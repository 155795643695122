import { useEffect, useState, useMemo } from "react";
import { Icon, InputGroup } from "rsuite";
import { useTranslation } from "react-i18next";
import { Input, Table, Button } from "@/Components/UI";
import { alphaKorService } from "@/Services";
import "./UserSelection.css";
import debounce from "lodash.debounce";
import { alphaIdService } from "../../Services";

const CustomerSelectionUsers = ({ onSelect, onAddUser, ...props }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchText, setSearchText] = useState();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [customersRes, usersRes] = await Promise.all([
          alphaKorService
            .getCustomers(page, pageSize, { searchText, userType: "F" })
            .catch(error =>
              console.error("Error while searching customers:", error),
            ),
          alphaIdService
            .getUsers(page, pageSize, searchText)
            .catch(error =>
              console.error("Error while searching users:", error),
            ),
        ]);

        let total = 0;
        let customerList = [];
        let userList = [];

        if (customersRes?.data?.data) {
          if (customersRes.data?.data.length) {
            customerList = customersRes.data.data.map(c => ({
              oib: c.oib,
              firstName: c.firstName,
              lastName: c.lastName,
              email: c.email,
              phoneNumber: c.mobile || c.phone,
            }));
          }

          total += customersRes.data?.total;
        }

        if (usersRes?.data?.data) {
          if (usersRes.data?.data.length) {
            userList = usersRes.data.data.map(c => ({
              oib: c.oib,
              firstName: c.firstName,
              lastName: c.lastName,
              email: c.email,
              phoneNumber: c.phoneNumber,
            }));
          }

          total += usersRes.data?.total;
        }

        setUsers({
          data: Array.from(new Set([...customerList, ...userList])).slice(0, 5),
          total,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, pageSize, searchText]);

  const handleChangePage = page => {
    setPage(page);
  };

  const handleChangePageSize = pageSize => {
    setPageSize(pageSize);
  };

  const handleOnSearch = useMemo(
    () =>
      debounce(value => {
        setSearchText(value);
        setPage(1);
      }, 400),
    [],
  );

  const pageSizeMenu = [
    {
      value: 5,
      label: 5,
    },
    {
      value: 10,
      label: 10,
    },
  ];

  const columns = [
    {
      title: t("Name"),
      key: "firstName",
      flexGrow: 2,
    },
    {
      title: t("Surname"),
      key: "lastName",
      flexGrow: 2,
    },
    {
      title: t("OIB"),
      key: "oib",
      flexGrow: 2,
    },
    {
      title: t("Email"),
      key: "email",
      flexGrow: 3,
    },
  ];

  return (
    <>
      <div
        className="search-container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}>
        <InputGroup inside>
          <Input placeholder={t("Search...")} onChange={handleOnSearch} />
          <InputGroup.Addon>
            <Icon icon="search" />
          </InputGroup.Addon>
        </InputGroup>
        <Button appearance="primary" onClick={onAddUser}>
          {t("Add user")}
        </Button>
      </div>
      <div className="user-container">
        <Table
          data={users?.data}
          totalItems={users?.total}
          pagination={true}
          loading={loading}
          page={page}
          pageSize={pageSize}
          pageSizeMenu={pageSizeMenu}
          columns={columns}
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
          onRowClick={onSelect}
        />
      </div>
    </>
  );
};

export default CustomerSelectionUsers;
