import './Tabs.css';
import {useEffect, useState} from "react";
import Panel from "../Panel/Panel"

const Tabs = ({ children, onChange, ...props }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleSelectTab = (tabId) => {
        return (ev) => {
            setSelectedTab(tabId)
            onChange && onChange(tabId)
        }
    }

    return <div className="tab-container">
        <div className="tabs">
            {children?.map((c, i) =>
                <div
                    key={i}
                    className={`tab-container-item ${selectedTab === i ? 'active' : ''}`}
                    onClick={handleSelectTab(i)}
                >
                    {c.props.name}
                </div>)}
        </div>
        {children?.map((c, i) => (i == selectedTab && c.props.children && <Panel className="tab-container__tab">{c.props.children}</Panel> || null))}
    </div>
}

export default Tabs;