import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Notification } from "@/Components/UI";
import { useTranslation } from "react-i18next";
import routes from "@/routes";
import { nextServices } from "../Services/NextServices";

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
  const THEME_KEY = "sc-admin-web.theme";

  const getTheme = () => {
    const theme =
      (localStorage.getItem(THEME_KEY) &&
        JSON.parse(localStorage.getItem(THEME_KEY))) ||
      "light";
    return theme == "dark";
  };

  const storeTheme = (darkMode) => {
    localStorage.setItem(
      THEME_KEY,
      JSON.stringify(darkMode ? "dark" : "light")
    );
  };

  const { t } = useTranslation();
  const [tenantId, setTenantId] = useState(
    "0505e146-d2e4-443f-9319-cc3542597913"
  );

  const [statusModal, setStatusModal] = useState({
    show: false,
    text: "",
    handleHide: () => setStatusModal({ ...setStatusModal, show: false }),
  });

  const openSuccessModal = ({ title, description }) => {
    Notification.success({
      title,
      description,
      className: "sc-notification sc-notification--success",
      placement: "bottomEnd",
      duration: 5000,
    });
  };

  const openErrorModal = ({ title, description }) => {
    Notification.error({
      title,
      description,
      className: "sc-notification sc-notification--error",
      placement: "bottomEnd",
      duration: 15000,
    });
  };

  const [crumbs, setCrumbs] = useState([]);
  const [openSidebarKeys, setOpenSidebarKeys] = useState(
    routes
      .filter((r) => !r.hidden)
      .map((r, i) => (r.expanded ? i + 1 : null))
      .filter((r) => r)
  );
  const [loading, setLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(getTheme() || false);
  const [sidebarData, setSidebarData] = useState({});
  const [fetchedNotificationLanguage, setFetchedNotificationLanguage] =
    useState();
  const location = useLocation();

  useEffect(() => {
    nextServices.removeBackendCookie("__Secure-next-auth.session-token.0");
  }, [location]);

  const isString = (value) => {
    return typeof value === "string" || value instanceof String;
  };

  const errorHandler = (error) => {
    const errorTitle =
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.title) ||
      t("Error");

    let validationErrors = [];
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      for (let [key, value] of Object.entries(error.response.data.errors)) {
        let validationError = (Array.isArray(value) && value[0]) || value;
        validationError = validationError.replace(`'${key}'`, "{{field}}");
        validationErrors.push(t(validationError, { field: t(key) }));
      }
    }

    const errorMessage =
      (error &&
        error.response &&
        error.response.data &&
        ((validationErrors &&
          validationErrors.length &&
          validationErrors.map((e) => <p>{e}</p>)) ||
          t(error.response.data.detail))) ||
      t("An error occurred while processing your request, please try again");

    openErrorModal({
      title: t(errorTitle),
      description: (isString(error) && t(error)) || errorMessage,
    });
  };

  const successHandler = (message) => {
    openSuccessModal({
      title: t(message) || t("Saved!"),
    });
  };

  const notificationHandler = ({ title, message }) => {
    Notification.info({
      title: t(title),
      description: t(message) || t("Info"),
      className: "sc-notification sc-notification--info",
      placement: "bottomEnd",
      duration: 5000,
    });
  };

  const th = (text) => {
    return t(text, { ns: "help" });
  };

  const handleSetDarkMode = (darkMode) => {
    storeTheme(darkMode);
    setDarkMode(darkMode);
  };

  return (
    <GlobalContext.Provider
      value={{
        tenantId,
        openSuccessModal,
        openErrorModal,
        crumbs,
        setCrumbs,
        openSidebarKeys,
        sidebarData,
        setSidebarData,
        setOpenSidebarKeys,
        loading,
        setLoading,
        errorHandler,
        successHandler,
        darkMode,
        setDarkMode: handleSetDarkMode,
        th,
        notificationHandler,
        fetchedNotificationLanguage,
        setFetchedNotificationLanguage,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
