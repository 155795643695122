import { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "@/Contexts/AuthContext";
import { hasAuth } from "@/Helpers/AuthHelper";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { SubscriptionContext } from "../../Contexts/SubscriptionContext";

const PrivateRoute = ({
  layout: Layout,
  component: Component,
  roles,
  claims,
  featureCode,
  ...rest
}) => {
  const history = useHistory();
  const { loginUser, handleLogin } = useContext(AuthContext);
  const { subscription } = useContext(SubscriptionContext);
  const isAuthorized = hasAuth(loginUser, roles, claims);

  useEffect(() => {
    if (!loginUser) {
      handleLogin();
    }

    if (loginUser && !isAuthorized) {
      history.push("/unauthorized");
    }

    const redirectUrl = sessionStorage.getItem("initialLocation");

    if (redirectUrl) {
      if (history.location.pathname == redirectUrl) {
        sessionStorage.removeItem("initialLocation");
      }
      history.push(redirectUrl);
    }

    if (loginUser && loginUser.language) {
      i18n.changeLanguage(loginUser.language || "hr");
    }
  }, []);

  // if (featureCode) {
  //   const featureCodes = subscription.subscriptionFeatures.map(
  //     x => x.featureCode,
  //   );
  //   if (!featureCodes.includes(featureCode)) {
  //     return null;
  //   }
  // }

  return (
    <>
      <Route
        {...rest}
        render={props =>
          loginUser && isAuthorized ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to="/" />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
