import { tagsUrl } from "../Config/Url";
import axios from "axios";

export default {
  getTags,
  getTag,
  createOrUpdateTag,
  deleteTag,
  getTagVisibilityOptions,
};

async function getTags(page, pageSize, visibility, tagSorting) {
  return axios
    .get(tagsUrl, {
      params: {
        page: page,
        take: pageSize,
        visibility,
        sort: tagSorting + "UpdatedAt",
      },
    })
    .then(res => res)
    .catch(err => err.response);
}

async function getTag(id) {
  return axios
    .get(tagsUrl + "/" + id)
    .then(res => res)
    .catch(err => err.response);
}

async function createOrUpdateTag(tagId, data) {
  if (tagId) {
    return axios.put(tagsUrl, { id: tagId, ...data });
  }

  return axios.post(tagsUrl, data);
}

async function deleteTag(tagId, { numberOfPosts, softDelete = false }) {
  return axios.put(tagsUrl + "/delete/" + tagId, {
    id: tagId,
    numberOfPosts,
    softDelete,
  });
}

async function getTagVisibilityOptions() {
  return [
    { label: "Public", value: "1" },
    { label: "Internal", value: "2" },
  ];
}
