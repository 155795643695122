import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ControlLabel } from "rsuite";
import { Button, Form, Textbox } from "@/Components/UI";
import AssignablePicker from "./AssignablePicker";
import "./TicketingFilterPopup.css";
import ClickAwayListener from "../../Components/ClickAwayListener";
import { Checkbox } from "../../Components/UI";

const TicketingFilterPopup = ({
  onChange,
  onSubmit,
  onClear,
  value,
  onClickAway,
}) => {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState(value);

  const handleFilterChange = values => {
    setFilterValues(values);
    onChange && onChange(values);
  };

  const handleClearClick = () => {
    setFilterValues();
    onClear();
  };

  const handleSubmit = () => {
    onSubmit(filterValues);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Form formValue={filterValues} onChange={handleFilterChange}>
        <div className="filter-container box-container filled">
          <div>
            <Textbox title={t("Ticket created by")} name="createdByName" />
          </div>
          <div>
            <Textbox title={t("Ticket name")} name="name" />
          </div>
          <div className="filter-container__span_columns">
            <ControlLabel>{t("Assigned to")}</ControlLabel>
            <Form.Control
              accepter={props => (
                <AssignablePicker placeholder="-" {...props} />
              )}
              valueKey="id"
              labelKey="displayName"
              name="assignedToId"
            />
          </div>
          <div className="d-flex">
            <ControlLabel>{t("Contact center tickets only")}</ControlLabel>
            <Form.Control
              accepter={Checkbox}
              name="isContactCenter"
              checked={filterValues?.isContactCenter}
              value={!filterValues?.isContactCenter}
            />
          </div>
        </div>
        <div className="filter-container__buttons">
          <Button onClick={handleClearClick}>{t("Reset all")}</Button>
          <Button appearance="trigger" onClick={handleSubmit}>
            {t("Apply filters")}
          </Button>
        </div>
      </Form>
    </ClickAwayListener>
  );
};

export default TicketingFilterPopup;
