import { Icon, Button, Modal } from "@/Components/UI";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const SCModalConfirm = ({
  show,
  title,
  showModal,
  onClose,
  onClickOk,
  closeModal,
  onSubmit,
  onCancel,
  content,
  textModal,
  loading,
  icon,
  disableSubmit,
  ...props
}) => {
  const {t} = useTranslation();
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (args) => {
    try {
      setSubmitting(true)
      onSubmit && await onSubmit(args) || onClickOk && await onClickOk(args);
      //onSubmit(args);
    }
    finally {
      setSubmitting(false)
    }
  }

  return (
   
      <Modal backdrop="static" show={show || showModal} onHide={onClose || onCancel || closeModal} {...props}>
        {title && (
          <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        ) || null}
        <Modal.Body style={{display:'flex', alignItems:'center', gap: '2rem'}} className="modal-confirm__body">
          {icon || (
            <Icon
              icon="remind"
              size="2x"
              style={{
                color: "#ffb300",
              }}
            />
          )}
          {content || textModal || ''}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} disabled={disableSubmit} loading={submitting} appearance="primary">
            {t('OK')}
          </Button>
          <Button onClick={onCancel || closeModal}>
            {t('Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

  );
};

export default SCModalConfirm;
