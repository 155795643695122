import React from "react";
import { useContext } from "react";
import { BarStack } from "@visx/shape";
import { useTranslation } from "react-i18next";
import { Group } from "@visx/group";
import { Grid } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { LegendOrdinal } from "@visx/legend";
import { localPoint } from "@visx/event";
import { formatShortDate } from "../../../Helpers/DateHelper";
import { AuthContext } from "@/Contexts/AuthContext";

const PaymentsBarChart = ({
  width,
  height,
  margin,
  events = false,
  invoices,
}) => {
  const { loginUser, handleLogin } = useContext(AuthContext);
  const { t } = useTranslation();

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const successfulColor = "rgb(85, 125, 134)";
  const unsuccessfulColor = "#f33d15";

  const background = "#28272c";
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 200,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
  };
  const data = invoices.map(d => {
    return {
      date: d.date,
      "Successful transactions": d.successfullTransaction,
      "Failed transactions": d.errorTransaction,
      /*"TransactionValue": d.isSuccessfull ? d.transactionDetailsDto.map(item=>item.paidValue).reduce((partialSum, v) => partialSum + v, 0) : 0,*/
    };
  });
  const keys = Object.keys(data[0]).filter(
    d => d !== "date" && d !== "TransactionValue",
  );

  const totalInvoices = data.reduce((allTotals, currentItem) => {
    const totalInvoice = keys.reduce((dailyTotal, k) => {
      dailyTotal += Number(currentItem[k]);
      return dailyTotal;
    }, 0);

    allTotals.push(totalInvoice);
    return allTotals;
  }, []);

  const formatDate = date => {
    return formatShortDate(date, loginUser.language);
  };

  // accessors
  const getDate = d => d.date;

  // scales
  const dateScale = scaleBand({
    domain: data.map(getDate),
    padding: 0.2,
  });
  const invoiceScale = scaleLinear({
    domain: [0, Math.max(...totalInvoices)],
    nice: true,
  });
  const colorScale = scaleOrdinal({
    domain: keys,
    range: [successfulColor, unsuccessfulColor], //redosljed boja u grafu
  });

  let tooltipTimeout = 10;

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  //if (width < 1) return null;
  // bounds
  const xMax = width;
  const yMax = height - margin.top - 100;

  console.log("Bar chart data:", width, height, xMax, yMax);

  dateScale.rangeRound([0, xMax]);
  invoiceScale.range([yMax, 0]);

  return width < 1 ? null : (
    <div style={{ width, height }}>
      <svg ref={containerRef} width={width} height={height}>
        {/* Pozadina na grafu*/}
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          rx={3}
          fill={background}
        />

        {/* Mreža na grafu u pozadini */}
        <Grid
          top={margin.top + 50}
          //left={margin.left}
          xScale={dateScale}
          yScale={invoiceScale}
          width={xMax}
          height={yMax}
          stroke="white"
          strokeOpacity={0.1}
          xOffset={dateScale.bandwidth() / 2}
        />

        {/* Bar stupci od grafa*/}
        <Group top={margin.top + 50}>
          <BarStack
            data={data}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={invoiceScale}
            color={colorScale}>
            {barStacks =>
              barStacks.map(barStack =>
                barStack.bars.map(bar => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onClick={() => {
                      if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                    }}
                    onMouseLeave={() => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                    }}
                    onMouseMove={event => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      // TooltipInPortal expects coordinates to be relative to containerRef
                      // localPoint returns coordinates relative to the nearest SVG, which
                      // is what containerRef is set to in this example.
                      const eventSvgCoords = localPoint(event);
                      const left = bar.x + bar.width / 2;
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: left,
                      });
                    }}
                  />
                )),
              )
            }
          </BarStack>
        </Group>

        {/* X os na grafu */}
        <AxisBottom
          top={yMax + margin.top + 50}
          scale={dateScale}
          tickFormat={formatDate}
          stroke={successfulColor}
          tickStroke={successfulColor}
          tickLabelProps={() => ({
            fill: "#ffffff", //boja za datume na x osi
            fontSize: 9,
            textAnchor: "middle",
            fontWeight: "bold",
          })}
        />
      </svg>

      {/* Legenda na vrhu grafa*/}
      <div
        style={{
          position: "absolute",
          top: margin.top + 3 / 2 - 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          fontSize: "14px",
          color: "white",
        }}>
        <LegendOrdinal
          scale={colorScale}
          direction="row"
          labelFormat={(d, i) => t(d)}
          labelMargin="0px 15px 0 0"
        />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}>
          <div style={{ color: colorScale(tooltipData.key), padding: "3% 3%" }}>
            <strong>{t(tooltipData.key)}</strong>
          </div>
          <div style={{ padding: "3% 3%" }}>
            {tooltipData.bar.data[tooltipData.key]}
          </div>
          <div style={{ padding: "3% 3%" }}>
            <small>{formatDate(getDate(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
};

export default PaymentsBarChart;
