import { FlexboxGrid } from 'rsuite';

const SCFlexboxGrid = (props) => {
    return (
        <FlexboxGrid {...props}>{props.children}</FlexboxGrid>
    );
}

const SCFlexboxGridItem = (props) => {
    return (
        <FlexboxGrid.Item {...props}>{props.children}</FlexboxGrid.Item>
    );
}

SCFlexboxGrid.Item = SCFlexboxGridItem;

export default SCFlexboxGrid;