import React, { useEffect, useRef, useState} from "react";
const defaultStyle = {
    display: "block",
    overflow: "hidden",
    resize: "none",
    width: "100%",
};

const InputAutosize = ({ style = defaultStyle, value, onChange, ...props }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [value])

    return (
        <textarea
            ref={textareaRef}
            style={style}
            {...props}
            value={value}
            onChange={e=> onChange(e.target.value)}
        />
    );
}

export default InputAutosize;