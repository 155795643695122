import { invoicesApiUrl } from "../Config/Url";
import axios from "axios";

export const invoiceService = {
  getInvoices,
  addUserToTheService,
  removeUserFromTheService,
  getVendors,
};

async function getInvoices(
  userId,
  invoiceId,
  dateFrom,
  dateTo,
  value,
  page,
  take,
  onlyUnPaidInvoices = false
) {
  return axios
    .get(invoicesApiUrl, {
      headers: { userId: userId },
      params: {
        invoiceId: invoiceId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        value: value,
        page: page,
        take: take,
        onlyUnPaidInvoices: onlyUnPaidInvoices,
      },
    })
    .then((err) => err)
    .catch((err) => err.response);
}

async function addUserToTheService({
  customerOib,
  addedCustomerOib,
  vendorOib,
  addressExternalId,
}) {
  return axios.post(`${invoicesApiUrl}/services/manage-team`, {
    customerOib,
    addedCustomerOib,
    vendorOib,
    addressExternalId,
    type: 1,
  });
}

async function removeUserFromTheService({
  customerOib,
  addedCustomerOib,
  vendorOib,
  addressExternalId,
}) {
  return axios.post(`${invoicesApiUrl}/services/manage-team`, {
    customerOib,
    addedCustomerOib,
    vendorOib,
    addressExternalId,
    type: 0,
  });
}

async function getVendors() {
  return axios.get(`${invoicesApiUrl}/vendor/all`);
}
