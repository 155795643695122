import  { createContext, useState } from "react";


export const SubscriptionContext = createContext();

const SubscriptionContextProvider = (props) => {
  const [subscription, setSubscription] = useState()

  return (
    <SubscriptionContext.Provider value={{ subscription, setSubscription }}>
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContextProvider;
