import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectPicker, Icon } from "@/Components/UI";
import { useAssignable } from "@/Hooks";
import "./AssignablePicker.css";
import debounce from 'lodash.debounce';

const AssignablePicker = ({pageSize, value, display, ...props}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();

    const { data: partyRoles, loading } = useAssignable({q: search, memberType: (!display || display == "All") && null || display?.toUpperCase(), assignedToId: value, take: pageSize || 30, page});

    const handleSearch = useMemo(
        () => debounce(setSearch, 400)
      , []);

    return (
        <SelectPicker 
            className="sc-assignable-picker"
            valueKey="id" 
            cleanable={false}
            //groupBy="parentCode"
            labelKey="displayName" 
            data={partyRoles.filter(p => p.type != "Tenant" && (!display || display == "All" || p.type == display))}
            onSearch={handleSearch}
            placeholder={t("Unassigned")}
            value={value}
            renderMenu={menu => {
                if (loading) {
                    return (
                        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                            <Icon icon="circle-o-notch" spin /> {t("Loading")}...
                        </p>
                    );
                }
                return menu;
            }}
            {...props} 
        />
    );
};

export default AssignablePicker;