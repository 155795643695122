import {
  alphaIdApiUserUrl,
  alphaIdApiUsersUrl,
  alphaIdApiRoleUrl,
  alphaIdApiPasswordUrl
} from "../Config/Url";
import axios from "axios";

async function createUser({ 
  userData
}) {
  return axios.post(alphaIdApiUserUrl, {
    ...userData,
    password: "strongPass321!",
  });
}

async function updateUser({
  id,
  userData
}) {
  return axios.put(alphaIdApiUserUrl, {
    id,
    ...userData
  });
}

async function updateUsers(arrayOfUsers) {
  return axios.put(alphaIdApiUsersUrl, [...arrayOfUsers]).then((res) => {});
}

async function getDistinctCLaims() {
  return await axios
    .get(alphaIdApiRoleUrl + "/GetDistinctCLaims")
    .then((res) => res.data);
}

async function getRoles() {
  return await axios.get(`${alphaIdApiRoleUrl}`).then((res) => res);
}

async function getRole(id) {
  return await axios.get(alphaIdApiRoleUrl + "/" + id).then((res) => res.data);
}

async function createOrUpdateRole(data) {  
  if (data.id) {
    return await axios.put(alphaIdApiRoleUrl, { ...data }).then((res) => res);
  }

  return axios.post(alphaIdApiRoleUrl, { ...data }).then((res) => res);
}

async function deleteRole(id) {
  return axios.delete(alphaIdApiRoleUrl + "/" + id).then((res) => res);
}

async function getUsers(page, take, searchText) {
  const skip = (page - 1) * take;
  const searchParamQuery = (searchText && `?searchParam=${searchText}`) || "";
  return axios.get(`${alphaIdApiUserUrl}/${skip}/${take}${searchParamQuery}`);
}

const changePassword = async ({ oldPassword, newPassword }) => {
  return axios.post(`${alphaIdApiPasswordUrl}/changePassword`, {
    oldPassword,
    newPassword,
  });
};

const getUserById = async (id) => {
  return axios.get(`${alphaIdApiUserUrl}/id/${id}`);
}



const getUsersByRoleId = async (roleId) => {
  return axios.get(`${alphaIdApiRoleUrl}/getUsersbyRoleid/${roleId}`);
}

const addUsersToRole = async (roleId, userIds) => {
  return axios.put(`${alphaIdApiRoleUrl}/addUsersToRole`, {
    roleId, userIds
  });
}

const removeUsersFromRole = async (roleId, userIds) => {
  return axios.put(`${alphaIdApiRoleUrl}/removeUsersFromRole`, {
    roleId, userIds
  });
}

const revokeUserConsent = async (userId) => {
  return axios.post(`${alphaIdApiUserUrl}/revoke-consent/${userId}`);
};


const getRoleById = async (roleId) => {
  return axios.get(`${alphaIdApiRoleUrl}/${roleId}`);
}

async function deleteUser(id) {
  return axios.delete(alphaIdApiUserUrl + "/" + id).then((res) => res);
}

const getUserByOib = async (oib) => {
  return axios.get(`${alphaIdApiUserUrl}/oib/${oib}`);
}

export default {
  createUser,
  updateUser,
  updateUsers,
  getDistinctCLaims,
  getRoles,
  getRole,
  createOrUpdateRole,
  deleteRole,
  getUsers,
  changePassword,
  getUserById,
  getUsersByRoleId,
  addUsersToRole,
  removeUsersFromRole,
  revokeUserConsent,
  getRoleById,
  deleteUser,
  getUserByOib
};
