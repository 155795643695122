import useSWR from 'swr';
import { chatApiUrl } from '@/Config/Url';
import {toQueryString} from "@/Helpers/UrlHelper";
import { swrFetcher } from '@/Helpers/SwrFetcher';


export const useMessages = ({chatId, ...params}) => {

    const { data, error, mutate } = useSWR(`${chatApiUrl}/chat/${chatId}/messages?${toQueryString(params)}`, swrFetcher, {
        refreshInterval: 5000
    });

    return {
        loading: !error && !data,
        data,
        error,
        mutate
    };
}

export const useUnreadMessagesCount = () => {

    const { data, error, mutate } = useSWR(`${chatApiUrl}/chat/unread-count`, swrFetcher);

    return {
        loading: !error && !data,
        data,
        error,
        mutate
    };
}