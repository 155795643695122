import { Toggle } from 'rsuite';
import './Toggle.css';

const SCToggle = ({ title, ...props }) => {
    
    return (
        <div className="toggle-container">
            <Toggle {...props} />
            <div>{title}</div>
        </div>
    );
}

export default SCToggle;