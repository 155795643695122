import { loginUrl } from "./Url";

export const getRedirectUri = () => {
  let url = window.location.href;
  let keyword = "admin";

  let index = url.indexOf(keyword);
  if (index !== -1) {
    let newUrl = url.substring(0, index + keyword.length);
    return newUrl;
  } else {
    return window.REACT_APP_URL;
  }
};

export const OidcConfig = {
  authority: loginUrl, //process.env.REACT_APP_IDENTITY_URL,
  client_id: "sc-admin-web",
  redirect_uri: `${(window?.REACT_APP_URL) || "http://localhost:3000"
    }/signin-oidc`, //`${process.env.REACT_APP_URL}/signin-oidc`,
  post_logout_redirect_uri: `${window.REACT_APP_URL || "http://localhost:3000"
    }/signout-oidc`, //`${process.env.REACT_APP_URL}/signout-oidc`,
  response_type: "code",
  scope: "openid profile email offline_access", //identity_ipcp_ntas_api skoruba_identity_admin_api
  silent_redirect_uri: `${window.REACT_APP_URL || "http://localhost:3000"
    }/silent-refresh`, //`${process.env.REACT_APP_URL}/silent-refresh`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  checkSessionInterval: 60000,
  accessTokenExpiringNotificationTime: 30,
};
