const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const getFileIconFromMimeType = (mimeType) => {
    let iconCssClass = 'file-o';
    if(/(jpe?g|png|gif)$/i.test(mimeType)) {
        iconCssClass = "file-image-o";
    }

    if(/(zip|7z|rar|tar)$/i.test(mimeType)) {
        iconCssClass = 'file-archive-o';
    }

    if(/(pdf)$/i.test(mimeType)) {
        iconCssClass = 'file-pdf-o';
    }

    if(/(docx?)$/i.test(mimeType)) {
        iconCssClass = 'file-word-o';
    }

    if(/(xlsx?)$/i.test(mimeType)) {
        iconCssClass = 'file-excel-o';
    }

    if(/(txt?)$/i.test(mimeType)) {
        iconCssClass = 'file-text-o';
    }

    return iconCssClass;
}

// https://www.gbmb.org/mb-to-bytes
export const niceBytes = (sizeInBytes) => {
    let l = 0, n = parseInt(sizeInBytes, 10) || 0;

    while(n >= 1024 && ++l){
        n = n/1024;
    }
    
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}