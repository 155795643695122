import './Tab.css';

const Tab = ({ tabs, selectedTabId, onTabClick }) => {
    return <div className="tab-container">
        <div className="tabs">
            {tabs?.map(t =>
                <div
                    key={t.id}
                    className={`tab-container-item ${selectedTabId === t.id ? 'active' : ''}`}
                    onClick={() => onTabClick(t.id)}
                >
                    {t.name}
                </div>)}
        </div>
    </div>
}

export default Tab;