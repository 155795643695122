import React, { useState, useEffect } from "react";
import { Dropdown } from "rsuite";
import "./Dropdown.css";
import { useTranslation } from "react-i18next";

const SCDropdown = ({
  options,
  hasDefaultOption,
  defaultLabel,
  defaultValue,
  labelKey,
  valueKey,
  onSelect,
  onSelectBaseValue = null,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedLabel, setSelectedLabel] = useState(t(props.title));
  const [selectedValue, setSelectedValue] = useState(props.initialvalue);

  useEffect(() => {
    setSelectedLabel(t(selectedLabel));
  }, [selectedLabel]);

  useEffect(() => {
    setSelectedLabel(t(props.title));
  }, [props.title]);

  const handleOnSelect = item => {
    setSelectedLabel((labelKey && t(item[labelKey])) || item.label);
    setSelectedValue((valueKey && item[valueKey]) || item.value);
    if (onSelect) {
      const value = item[valueKey] || item.value;
      onSelect(value == -1 ? onSelectBaseValue : value);
    }
  };

  useEffect(() => {
    setSelectedLabel(t(selectedLabel));
  }, [selectedLabel]);

  useEffect(() => {
    setSelectedLabel(t(props.title));
  }, [props.title]);

  return (
    <Dropdown {...props} activeKey={selectedValue} title={t(selectedLabel)}>
      {(hasDefaultOption && (
        <Dropdown.Item
          key={`ddl-default}`}
          eventKey={defaultValue || null}
          onSelect={() =>
            handleOnSelect({
              label: (defaultLabel && t(defaultLabel)) || t("All"),
              value: defaultValue || null,
            })
          }>
          {(defaultLabel && t(defaultLabel)) || t("All")}
        </Dropdown.Item>
      )) ||
        null}

      {options &&
        options.map((o, i) => {
          return (
            <Dropdown.Item
              key={`ddl-${i + 1}`}
              icon={o.icon || null}
              eventKey={(valueKey && o[valueKey]) || o.value}
              onSelect={() => {
                handleOnSelect(o);
              }}>
              {t((labelKey && o[labelKey]) || o.label)}
            </Dropdown.Item>
          );
        })}
    </Dropdown>
  );
};

export default SCDropdown;
