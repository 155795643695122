import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, ControlLabel } from 'rsuite';
import { Button, Form, Textbox, Panel, SelectPicker } from "@/Components/UI";
import VendorPicker from "./VendorPicker";
import './PaymentFilterPopup.css';
import ClickAwayListener from "../../Components/ClickAwayListener";

const PaymentFilterPopup = ({ visible, title, onChange, onSubmit, onClear, value, onClickAway, ...props }) => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState(value);

    const handleFilterChange = (values) => {
        setFilterValues(values);   
        onChange && onChange(values)     
    }

    const handleClearClick = () => {
        setFilterValues();
        onClear();
    }

    const handleSubmit = () => {
        onSubmit(filterValues);
    }

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Form formValue={filterValues} onChange={handleFilterChange}>
                <div className="filter-container box-container filled">
                    <div>
                        <Textbox title={t("Payment created by")} name="payerName" />
                    </div>
                    <div>
                        <Textbox title={t("Payment name")} name="paymentName" />
                    </div>
                    <div className="filter-container__span_columns">
                        <ControlLabel>{t("Vendors")}</ControlLabel>
                        <Form.Control accepter={(props) => 
                                <VendorPicker 
                                    placeholder="-"
                                    {...props} 
                                />
                            }
                            name="vendorId" />
                    </div>
                </div>
                <div className="filter-container__buttons">
                    <Button onClick={handleClearClick}>{t("Reset all")}</Button>
                    <Button appearance="trigger" onClick={handleSubmit}>{t("Apply filters")}</Button>
                </div>
            </Form>
        </ClickAwayListener>
    );
};

export default PaymentFilterPopup;