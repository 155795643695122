import useSWR from "swr";
import { ticketingApiUrl } from "@/Config/Url";
import { toQueryString } from "@/Helpers/UrlHelper";

export const useTickets = params => {
  const { data, error, mutate } = useSWR(
    `${ticketingApiUrl}/tickets?${toQueryString(params)}`,
  );

  return {
    loading: !error && !data,
    data,
    error,
    mutate,
  };
};

export const useDashboard = () => {
  return useSWR(`${ticketingApiUrl}/dashboard`);
};

export const useAssignable = params => {
  const { data, error } = useSWR(
    `${ticketingApiUrl}/tickets/assignable?${toQueryString(params)}`,
  );
  // console.log("Got assignable:", data)
  return {
    loading: !error && !data,
    data: (data && data.results) || [],
    error,
  };
};

export const useTicketAttachments = params => {
  const { data, error, mutate } = useSWR(
    `${ticketingApiUrl}/tickets/${params.ticketId}/attachments?${toQueryString(
      params,
    )}`,
  );

  return {
    loading: !error && !data,
    data,
    error,
    mutate,
  };
};
