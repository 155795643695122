import {Panel} from "rsuite";
import {Tooltip } from "@/Components/UI";

const Card = ({width, image, icon, tooltip, title, ...props}) => {

    const body = <Panel bordered bodyFill style={{ width: width }} {...props} tooltip={tooltip}>
        {image && <img src={image} width={width} /> || null}
        {icon && <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', padding: '2rem'}}>{icon}</div> || null}
       
        <Panel header={title}>
            {props.children}
        </Panel>
    </Panel> 

    return (
        tooltip && (
            <Tooltip text={tooltip}>
                {body}
            </Tooltip>
        ) || (
            body
        )
    )
}

export default Card;