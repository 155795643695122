import useSWR from 'swr';
import { organizationStructureUrl } from '@/Config/Url';
import { toQueryString } from "@/Helpers/UrlHelper";

export const useOrganizations = (params) => {
    const { data, error, mutate } = useSWR(`${organizationStructureUrl}/organizations?${toQueryString(params)}`);

    return {
        loading: !error && !data,
        data,
        error,
        mutate
    };
}

export const useOrganizationMembers = (params) => {
    const { data, error, mutate } = useSWR(`${organizationStructureUrl}/organizations/${params.organizationId}/members?${toQueryString(params)}`);

    return {
        loading: !error && !data,
        data,
        error,
        mutate
    };
}