import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Sidebar,
  Sidenav,
  Icon,
  Dropdown,
  Navbar,
  Nav,
  IconButton,
  Tooltip,
  Whisper,
  Toggle,
} from "rsuite";
import { Avatar, Permission } from "@/Components/UI";
import "./Sidebar.css";
import routes from "@/routes";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { AuthContext } from "@/Contexts/AuthContext";
import { hasAuth } from "@/Helpers/AuthHelper";
import { oidcClientService } from "@/Services/OidcClientService";
import UserInfo from "@/Components/UserInfo";
import LanguagePicker from "@/Components/LanguagePicker";
import AppLogo from "./app-logo-small.png";
import { SunIcon } from "@/Components/UI/CustomIcons/SunIcon/SunIcon";
import { OidcConfig } from "@/Config/OidcConfig";
import { Badge } from "../../UI";
import { notificationHooks } from "../../../Hooks";
import { useUnreadMessagesCount } from "@/Hooks/chatHooks";

import ClickAwayListener from "../../ClickAwayListener";
import { SubscriptionContext } from "../../../Contexts/SubscriptionContext";

const iconStyles = {
  width: 56,
  height: 56,
  lineHeight: "56px",
  textAlign: "center",
};

const appId = OidcConfig.client_id;
const clearUserMetadata = () => {
  try {
    sessionStorage.removeItem("initialLocation");
  } catch {}
};

const NavToggleMobile = ({ expand, onChange }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { loginUser, setLoginUser } = useContext(AuthContext);
  const { setDarkMode, darkMode } = useContext(GlobalContext);
  const triggerRef = React.createRef();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { data: unreadCount, mutate: mutateUnreadCount } =
    notificationHooks.useUnreadNotificationCount();

  const handleSignOutClick = () => {
    clearUserMetadata();
    oidcClientService.logout();
  };

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const handleChangeLanguage = (lang) => {
    setLoginUser({ ...loginUser, language: lang });
    i18n.changeLanguage(lang);
  };

  return (
    <Navbar
      appearance="subtle"
      className="sc-sidebar-toggle sc-sidebar-toggle--mobile"
    >
      <Navbar.Body>
        <ClickAwayListener onClickAway={() => setShowUserMenu(false)}>
          <Nav className="user__options">
            <Badge maxCount={10} content={unreadCount ? unreadCount : false}>
              <Avatar
                circle
                onClick={toggleUserMenu}
                className={"sc-avatar"}
                user={loginUser}
              />
            </Badge>
            <Dropdown
              placement="topStart"
              ref={triggerRef}
              trigger="none"
              menuStyle={{ marginLeft: "-5rem", marginBottom: "0.5rem" }}
              open={showUserMenu}
              renderTitle={(children) => null}
            >
              <Dropdown.Item></Dropdown.Item>
              <Dropdown.Item key={`sidebar-toggle-userInfo`}>
                <UserInfo user={loginUser} />
              </Dropdown.Item>

              <Dropdown.Item
                key={"sidebar-toggle-notifications"}
                componentClass={NavLink}
                to={"/profile/notifications"}
              >
                {t("My notifications")}
              </Dropdown.Item>
              <Dropdown.Item
                key={`sidebar-toggle-settings`}
                componentClass={NavLink}
                to={"/profile"}
              >
                {t("Profile")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleSignOutClick}
                key={`sidebar-toggle-signout`}
              >
                {t("Sign out")}
              </Dropdown.Item>
            </Dropdown>
          </Nav>
        </ClickAwayListener>

        <Nav style={{ display: "flex", alignItems: "center" }}>
          <Whisper
            trigger="hover"
            placement="right"
            key={`sc-sidebar__settings`}
            speaker={
              <Tooltip key={`sc-sidebar__menu-tooltip`}>
                {t("Application settings")}
              </Tooltip>
            }
          >
            <Permission claims={[`${appId}.settings.edit`]}>
              <IconButton
                style={{
                  marginLeft: "1rem",
                  marginRight: "2rem",
                  marginTop: "0.5rem",
                }}
                size="lg"
                icon={<Icon icon="cog" />}
                onClick={() => history.push("/settings")}
                circle
              />
            </Permission>
          </Whisper>
        </Nav>
        <Nav className="sc-theme">
          <Toggle
            className="sc-theme__toggle"
            checkedChildren={<Icon icon="moon-o" />}
            checked={darkMode}
            unCheckedChildren={<SunIcon />}
            onChange={setDarkMode}
          />
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

const NavToggle = ({ expand, onChange }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { loginUser, setLoginUser } = useContext(AuthContext);
  const { setDarkMode, darkMode } = useContext(GlobalContext);
  const triggerRef = React.createRef();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { data: unreadCount, mutate: mutateUnreadCount } =
    notificationHooks.useUnreadNotificationCount();

  const handleSignOutClick = () => {
    clearUserMetadata();
    oidcClientService.logout();
  };

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const handleChangeLanguage = (lang) => {
    setLoginUser({ ...loginUser, language: lang });
    i18n.changeLanguage(lang);
  };

  return (
    <Navbar appearance="subtle" className="sc-sidebar-toggle">
      <Navbar.Body>
        {(expand && (
          <>
            <ClickAwayListener onClickAway={() => setShowUserMenu(false)}>
              <Nav className="user__options">
                <Badge
                  maxCount={10}
                  content={unreadCount ? unreadCount : false}
                >
                  <Avatar
                    circle
                    onClick={toggleUserMenu}
                    className={"sc-avatar"}
                    user={loginUser}
                  />
                </Badge>
                <Dropdown
                  placement="topStart"
                  ref={triggerRef}
                  trigger="none"
                  menuStyle={{ marginLeft: "-5rem", marginBottom: "0.5rem" }}
                  open={showUserMenu}
                  renderTitle={(children) => null}
                >
                  <Dropdown.Item></Dropdown.Item>
                  <Dropdown.Item key={`sidebar-toggle-userInfo`}>
                    <UserInfo user={loginUser} />
                  </Dropdown.Item>

                  <Dropdown.Item
                    key={"sidebar-toggle-notifications"}
                    componentClass={NavLink}
                    to={"/profile/notifications"}
                  >
                    {t("My notifications")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    key={`sidebar-toggle-settings`}
                    componentClass={NavLink}
                    to={"/profile"}
                  >
                    {t("Profile")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleSignOutClick}
                    key={`sidebar-toggle-signout`}
                  >
                    {t("Sign out")}
                  </Dropdown.Item>
                </Dropdown>
              </Nav>
            </ClickAwayListener>
            <Nav pullRight style={{ display: "flex", alignItems: "center" }}>
              <Nav.Item
                key={`sidebar-toggle-button`}
                onClick={onChange}
                style={{ width: 56, textAlign: "center" }}
              >
                <Icon icon={expand ? "angle-left" : "angle-right"} />
              </Nav.Item>
            </Nav>
            <Nav pullRight style={{ display: "flex", alignItems: "center" }}>
              <Whisper
                trigger="hover"
                placement="right"
                key={`sc-sidebar__settings`}
                speaker={
                  <Tooltip key={`sc-sidebar__menu-tooltip`}>
                    {t("Application settings")}
                  </Tooltip>
                }
              >
                <Permission claims={[`${appId}.settings.edit`]}>
                  <IconButton
                    style={{
                      marginLeft: "1rem",
                      marginRight: "2rem",
                      marginTop: "0.5rem",
                    }}
                    size="lg"
                    icon={<Icon icon="cog" />}
                    onClick={() => history.push("/settings")}
                    circle
                  />
                </Permission>
              </Whisper>
            </Nav>
            <Nav pullRight className="sc-theme">
              <Toggle
                className="sc-theme__toggle"
                checkedChildren={<Icon icon="moon-o" />}
                checked={darkMode}
                unCheckedChildren={<SunIcon />}
                onChange={setDarkMode}
              />
            </Nav>
          </>
        )) || (
          <>
            <Nav pullRight style={{ display: "flex", alignItems: "center" }}>
              <Nav.Item
                key={`sidebar-toggle-button`}
                onClick={onChange}
                style={{ width: 56, textAlign: "center" }}
              >
                <Icon icon={expand ? "angle-left" : "angle-right"} />
              </Nav.Item>
            </Nav>
          </>
        )}
      </Navbar.Body>
    </Navbar>
  );
};

const CustomNavLink = (props) => {
  return (
    <NavLink className="sc-sidebar__icon" {...props}>
      {props.children}
    </NavLink>
  );
};

const AppSidebar = ({ expanded, toggleSidebar, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openSidebarKeys, setOpenSidebarKeys } =
    React.useContext(GlobalContext);
  const { loginUser } = useContext(AuthContext);
  const { data: unreadChatMessagesCount } = useUnreadMessagesCount();
  const [sidebarData, setSidebarData] = useState({});
  const { subscription } = useContext(SubscriptionContext);

  useEffect(() => {
    setSidebarData((old) => ({
      ...old,
      ["/ticketing"]: {
        content: unreadChatMessagesCount || 0,
        tooltip: t("New tickets/messages"),
      },
    }));
  }, [unreadChatMessagesCount]);

  const handleOpenChange = (data) => {
    setOpenSidebarKeys(data);
  };

  const subscriptionFeatures = subscription?.subscriptionFeatures.map(
    (sf) => sf.featureCode
  );

  const filteredRoutes = routes
    .filter((x) => !x.hidden)
    .filter(
      (r) => !r.featureCode || subscriptionFeatures?.includes(r.featureCode)
    );

  useEffect(() => {
    filteredRoutes.forEach((route, index) => {
      if (route?.children?.length >= 0) {
        const startPath = window.location.pathname.substring(
          0,
          route.path.length
        );
        if (startPath === route.path) {
          setOpenSidebarKeys([index + 1]);
        }
      }
    });
  }, []);

  return (
    <>
      <Sidebar
        className={`sc-app-sidebar ${
          expanded ? "sc-app-sidebar--expanded" : "sc-app-sidebar--collapsed"
        }`}
        width={expanded ? 320 : 57}
        collapsible
      >
        <Sidenav
          className="sc-sidebar"
          expanded={expanded}
          openKeys={openSidebarKeys}
          onOpenChange={handleOpenChange}
          appearance="subtle"
        >
          <a href={window?.location?.origin}>
            <Sidenav.Header key={`sc-sidebar__header`}>
              <div className="sc-sidebar__header" key={`sc-sidebar__header`}>
                <div className="sc-sidebar__details">
                  <div className="sc-sidebar__logo">
                    <img src={subscription?.mobLogo?.url} alt="logo" />
                  </div>
                  {expanded && (
                    <div className="sc-sidebar__title">
                      {subscription?.name ?? "One City App"}
                    </div>
                  )}
                </div>
              </div>
            </Sidenav.Header>
          </a>
          <Sidenav.Body key={`sc-sidebar__body`}>
            <Nav key={`sc-sidebar__nav`}>
              {filteredRoutes.map((route, i) => {
                if (
                  route.children &&
                  route.children
                    .filter((r) => !r.hidden)
                    .filter(
                      (r) =>
                        !r.featureCode ||
                        subscriptionFeatures?.includes(r.featureCode)
                    ).length
                ) {
                  return (
                    route.isPublic ||
                    (hasAuth(loginUser, route.roles, route.claims) && (
                      <Dropdown
                        eventKey={i + 1}
                        key={`sc-sidebar__item-${i + 1}`}
                        trigger="hover"
                        className="sc-sidebar__item"
                        renderTitle={() => {
                          if (!expanded) {
                            return (
                              <div
                                className="sc-sidebar__icon sc-sidebar__icon--collapsed"
                                key={`sc-sidebar__icon-${i + 1}`}
                              >
                                {route.icon || null}
                              </div>
                            );
                          }

                          return (
                            <div
                              className="sc-sidebar__item"
                              key={`sc-sidebar__item-${i + 1}`}
                            >
                              <i className="rs-dropdown-menu-toggle-icon rs-icon"></i>
                              <div
                                className="sc-sidebar__icon"
                                key={`sc-sidebar__icon-${i + 1}`}
                              >
                                {route.icon || null}
                              </div>
                              <div
                                className="sc-sidebar__text"
                                key={`sidebar-ddl-item-title-${i + 1}`}
                              >
                                <NavLink
                                  className="sc-sidebar__link"
                                  key={`sidebar-ddl-item-link-${i + 1}`}
                                  to={route.path || null}
                                >
                                  {t(route.name)}
                                </NavLink>

                                {(route.action &&
                                  route.action.url &&
                                  hasAuth(
                                    loginUser,
                                    route.action.roles,
                                    route.action.claims
                                  ) && (
                                    <Whisper
                                      trigger="hover"
                                      placement="right"
                                      key={`sc-sidebar__whisper-${i + 1}`}
                                      speaker={
                                        <Tooltip
                                          key={`sc-sidebar__tooltip-${i + 1}`}
                                        >
                                          {(route.action.tooltip &&
                                            t(route.action.tooltip)) ||
                                            ""}
                                        </Tooltip>
                                      }
                                    >
                                      <IconButton
                                        className="sc-sidebar__action"
                                        icon={route.action.icon || null}
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          history.push(route.action.url);
                                        }}
                                        key={`sc-sidebar__action-${i + 1}`}
                                        circle
                                        size="md"
                                      />
                                    </Whisper>
                                  )) || (
                                  <div className="sc-sidebar__action sc-sidebar__action--no-action">
                                    {(sidebarData &&
                                      sidebarData[route.path] &&
                                      sidebarData[route.path].content && (
                                        <Badge
                                          content={
                                            sidebarData[route.path]?.content ||
                                            false
                                          }
                                          tooltip={
                                            sidebarData[route.path]?.tooltip ||
                                            false
                                          }
                                        />
                                      )) ||
                                      null}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }}
                        title={
                          <NavLink
                            className="sc-sidebar__link"
                            key={`sidebar-ddl-item-link-${i + 1}`}
                            to={route.path || null}
                          >
                            {t(route.name)}
                          </NavLink>
                        }
                        icon={route.icon || null}
                        placement="rightStart"
                      >
                        {route.children
                          .filter((r) => !r.hidden)
                          .filter(
                            (r) =>
                              !r.featureCode ||
                              subscriptionFeatures?.includes(r.featureCode)
                          )
                          .map(
                            (child, j) =>
                              child.isPublic ||
                              (hasAuth(
                                loginUser,
                                child.roles,
                                child.claims,
                                child.path
                              ) &&
                                (((child.path.includes("http://") ||
                                  child.path.includes("https://")) && (
                                  <Dropdown.Item
                                    className="sc-sidebar__list"
                                    key={`sc-sidebar__list-${i + 1}-${j + 1}-${
                                      child.path
                                    }`}
                                    href={child.path}
                                    icon={child.icon || null}
                                    eventKey={`${i + 1}-${j + 1}`}
                                  >
                                    {t(child.name)}
                                  </Dropdown.Item>
                                )) || (
                                  <Dropdown.Item
                                    className="sc-sidebar__list"
                                    key={`sc-sidebar__list-${i + 1}-${j + 1}-${
                                      child.path
                                    }`}
                                    componentClass={NavLink}
                                    to={child.path || null}
                                    icon={child.icon || null}
                                    eventKey={`${i + 1}-${j + 1}`}
                                  >
                                    {t(child.name)}
                                  </Dropdown.Item>
                                ))) ||
                              null
                          )}
                      </Dropdown>
                    )) ||
                    null
                  );
                }

                return (
                  route.isPublic ||
                  (hasAuth(loginUser, route.roles, route.claims) &&
                    (((route.path.includes("http://") ||
                      route.path.includes("https://")) && (
                      <Nav.Item
                        href={route.path}
                        target="_blank"
                        icon={route.icon || null}
                        key={`sc-sidebar__item-${i + 1}`}
                        className={route.className || "sc-sidebar__item"}
                      >
                        <span className="sc-sidebar__link--external">
                          {t(route.name)}
                        </span>
                      </Nav.Item>
                    )) || (
                      <Nav.Item
                        exact
                        componentClass={CustomNavLink}
                        key={`sc-sidebar__item-${i + 1}`}
                        eventKey={i + 1}
                        to={route.path || null}
                        icon={route.icon || null}
                        className={route.className || "sc-sidebar__item"}
                      >
                        {t(route.name)}
                      </Nav.Item>
                    ))) ||
                  null
                );
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
        <NavToggle
          key={`sc-sidebar__toggle`}
          expand={expanded}
          onChange={toggleSidebar}
        />
      </Sidebar>
      {(!expanded && (
        <NavToggleMobile
          key={`sc-sidebar__toggle`}
          expand={expanded}
          onChange={toggleSidebar}
        />
      )) ||
        null}
    </>
  );
};

export default AppSidebar;
