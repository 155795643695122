import {  useContext, useEffect, useState } from "react";
import App from "./App";
import SubscriptionContextProvider from "@/Contexts/SubscriptionContext";


function AppContainer() {
  return (
    <SubscriptionContextProvider>
        <App />
    </SubscriptionContextProvider>
  );
}

export default AppContainer;
