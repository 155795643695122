import { Notification } from 'rsuite';

const SCNotification = {
    success: (props) => Notification.success(props),
    error: (props) => Notification.error(props),
    warning: (props) => Notification.error(props),
    info: (props) => Notification.info(props),
    open: (props) => Notification.open(props)
}

export default SCNotification;