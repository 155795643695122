
import "./Loader.css";

const Loader = ({noOverlay, ...props}) => {
    return (
        <div className={`sc-loader${noOverlay && ' sc-loader--no-overlay' || ''}`}>
            <div className="sc-loader__logo"></div>
        </div>
    )
}

export default Loader;