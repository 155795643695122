import { isValid, format, parseISO } from "date-fns";
import { hr, it, de, enUS as en } from "date-fns/locale";

/**
 * Check this link https://date-fns.org/v1.30.1/docs/format for proper formatting.
 */

export const datePickerDateToUtc = (date) => {
  if (!date) return date;

  const stringDate = format(date, "yyyy-MM-dd");
  return `${stringDate}T00:00:00`;
};

export const formatUtcToDate = (date, locale) => {
  if (!date) return date;

  const transformedDate = date instanceof Date ? date.toISOString(): date;

  const gtmDate = parseISO(transformedDate.includes("Z") && transformedDate || transformedDate + "Z")
  return format(gtmDate, "P", {
    locale: getLocaleFromString(locale),
  });
};

export const formatUtcToYYYY_MM_DD = (date) => {
  let x = date;

  // if date format is YYYY-MM-DDT00:00:00 we have to parse it
  if (isValid(parseISO(date)))
    x = parseISO(date);

  // we expect here date format YYYY-MM-DDT00:00:00Z
  return x ? format(x, 'yyyy-MM-dd') : x;
};

export const today = (locale) => {
  const transformedDate = new Date().toISOString();

  const gtmDate = parseISO(transformedDate.includes("Z") && transformedDate || transformedDate + "Z")
  return format(gtmDate, "P", {
    locale: getLocaleFromString(locale),
  });
};

export const yesterday = () => {
  return new Date().setDate(new Date().getDate() - 1);
};

export const formatShortDate = (date, locale) => {
  if (!date) return date;

  const transformedDate = date instanceof Date ? date.toISOString(): (new Date(date)).toISOString();

  const gtmDate = parseISO(transformedDate.includes("Z") && transformedDate || transformedDate + "Z")
  return format(gtmDate, "MMM yyyy", {
    locale: getLocaleFromString(locale),
  });
};

export const formatTo_YYYYMMDD = (date) => {
  if (!date) return date;
  return format(date, "yyyy-MM-dd");  
};

export const formatUtcToYYYYMMDDHH_24MMSS = (date) => {
  if (!date) return date;

  const gtmDate = new Date(date);
  return format(gtmDate, "yyyyMMdd_HHmmss");
};

const getLocaleFromString = (locale) => {
  switch (locale) {
    default:
    case "hr":
      return hr;
      break;

    case "en":
      return en;
      break;

    case "de":
      return de;
      break;
  }
};

export const formatUtcToTime = (date, locale) => {
  if (!date) return date;

  const transformedDate = date instanceof Date ? date.toISOString(): date;

  //console.log(getLocaleFromString(locale));
  return format(parseISO(transformedDate.includes("Z") && transformedDate || transformedDate + "Z"), "p", {
    locale: getLocaleFromString(locale),
  });
};

export const formatUtcToDateAndTime = (date, locale) => {
  if (!date) return date;

  const transformedDate = date instanceof Date ? date.toISOString(): date;

  //console.log(getLocaleFromString(locale));
  return format(parseISO(transformedDate.includes("Z") && transformedDate || transformedDate + "Z"), "Pp", {
    locale: getLocaleFromString(locale),
  });
};

export const formatTimeAgo = (i18n, dateTime) => {
  const now = new Date();

  const parsedDate = parseISO(dateTime.includes("Z") && dateTime || dateTime + "Z");
  const microSecondsDiff = Math.abs(parsedDate.getTime() - now.getTime());

  //Days diff
  const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60 * 24));
  if (daysDiff > 0 && daysDiff == 1) {
    return i18n("A day ago");
  } else if (daysDiff > 1) {
    return i18n("{{time}} days ago", { time: daysDiff });
  }

  //Hours diff
  const hoursDiff = Math.round(microSecondsDiff / (1000 * 60 * 60));
  if (hoursDiff > 0 && hoursDiff == 1) {
    return i18n("{{time}} hour ago", { time: hoursDiff });
  } else if (
    hoursDiff > 0 &&
    (hoursDiff == 2 || hoursDiff == 3 || hoursDiff == 4)
  ) {
    return i18n("{{time}} hours ago ", { time: hoursDiff });
  } else if (hoursDiff > 0 && hoursDiff <= 24) {
    return i18n("{{time}} hours ago", { time: hoursDiff });
  }

  //Minutes diff
  const minutesDiff = Math.round(microSecondsDiff / (1000 * 60));
  if (minutesDiff < 1) {
    return i18n("A few seconds ago");
  } else if (minutesDiff == 1) {
    return i18n("minutes ago");
  } else if (
    minutesDiff > 0 &&
    (minutesDiff == 2 || minutesDiff == 3 || minutesDiff == 4)
  ) {
    return i18n("{{time}} minutes ago ", { time: minutesDiff }); //Space after ago is placed intentionally
  } else if (minutesDiff > 0 && minutesDiff <= 60) {
    return i18n("{{time}} minutes ago", { time: minutesDiff });
  }
};


export const parseDateTime = (dateTime) => {
  if(dateTime && dateTime instanceof Date) {
    return dateTime
  }

  return dateTime && parseISO(dateTime.includes("Z") && dateTime || dateTime + "Z") || null;
} 
