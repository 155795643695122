import { organizationStructureUrl } from "../Config/Url";
import axios from "axios";
import IOrganizations from "../Contracts/OrganizationStructure/IOrganizations";
import IOrganization from "../Contracts/OrganizationStructure/IOrganization";
import IOrganizationResult from "../Contracts/OrganizationStructure/IOrganizations";
import IIndividual from "../Contracts/OrganizationStructure/IIndividual";
import IOrganizationMember from "../Contracts/OrganizationStructure/IOrganizationMember";

export const getOrganizations = async (page, take): Promise<any> =>  {
    try {
        const result = await axios.get<IOrganizationResult>(`${organizationStructureUrl}/organizations`);
        return result;
    } catch (error) {
        const emptyResult: IOrganizationResult | null = null; 
        return emptyResult;
    }
}

export const getOrganizationGraph = async () =>  {
    try {
        const result = await axios.get<any>(`${organizationStructureUrl}/organizations/graph/`);

        return result.data;
    } catch (error) {
        const emptyResult: IOrganizations[] = []; 
        return emptyResult;
    }
}

export const createOrganization = async (data: any) =>  {
    const result = await axios.post<any>(`${organizationStructureUrl}/organizations`, data);
    return await result.data;
}

export const updateOrganization = async (data: any) =>  {
    const result = await axios.put<any>(`${organizationStructureUrl}/organizations/${data.organizationId}`, data);
    return await result.data;
}

export const removeOrganization = async (organizationId: string) =>  {
    const result = await axios.delete<any>(`${organizationStructureUrl}/organizations/${organizationId}`);
    return await result.data;
}

export const getOrganizationById = async (organizationId: string): Promise<IOrganization | null> =>  {
    const result = await axios.get<IOrganization | null>(`${organizationStructureUrl}/organizations/${organizationId}`);
    return await result.data;
}


export const addMemberToOrganization = async (organizationId: string, member: IOrganizationMember): Promise<string> =>  {
    const result = await axios.post<string>(`${organizationStructureUrl}/organizations/${organizationId}/members/assign`, {
        organizationId: organizationId,
        ...member
    });

    const response = await result.data;
    return response;
}

export const updateOrganizationMember = async (organizationId: string, member: IOrganizationMember): Promise<string> =>  {
    const result = await axios.put<string>(`${organizationStructureUrl}/organizations/${organizationId}/members/${member.id}`, {
        organizationId: organizationId,
        ...member
    });

    const response = await result.data;
    return response;
}


export const removeOrganizationMember = async (organizationId: string, member: IOrganizationMember): Promise<string> =>  {
    const result = await axios.delete<string>(`${organizationStructureUrl}/organizations/${organizationId}/members/${member.id}`);

    const response = await result.data;
    return response;
}