import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { languageApiUrl } from "./Config/Url";
import axios from "axios";
import SubscriptionService from "./Services/SubscriptionService";
import { languageService } from "./Services/LanguageService";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "hr",
    fallbackLng: "hr",
    debug: false,
    ns: ["translation", "help"],
    nsDefault: "translation",
    nsSeparator: false,
    keySeparator: false,
    backend: {
      allowMultiLoading: true,
      loadPath: async (lng) => {
        const language = await languageService.getLanguageByCultureCode([
          lng[0],
        ]);

        return `${languageApiUrl}/translations/json?languageId=${
          language?.data?.id || 1
        }&typeId=3`;
      },

      request: async (options, url, payload, callback) => {
        let subscription;
        try {
          subscription =
            await SubscriptionService.getSubscriptionIdByDomainName(
              window.location.host
            );
        } catch (error) {
          console.log("Error while fetching subscription id: ", error);
        }

        axios.defaults.headers.common["X-Tenant-Id"] = subscription?.data;

        const response = await axios.get(url);
        callback(null, response);
      },
    },
  });

export default i18n;
