import React from "react";
import { Input, InputGroup,  Icon } from "rsuite";


const SCSearch = ({ placeholder, size, ...props }) => (
  <InputGroup {...props} inside size={size}>
    <InputGroup.Addon>
      <Icon icon="search" />
    </InputGroup.Addon>
    <Input placeholder={placeholder} />
  </InputGroup>
);

export default SCSearch;
