import { Tooltip, Whisper } from 'rsuite';

const SCTooltip = ({text, content, ...props}) => {
    return (
        <Whisper
                {...props}
                speaker={
                    <Tooltip>{text || content}</Tooltip>
                }
            >
            {props.children}
        </Whisper>
    )
}

export default SCTooltip;