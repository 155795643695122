import React from "react";
import { Panel, Icon } from "rsuite";
import { formatUtcToDateAndTime } from '@/Helpers/DateHelper';
import { useTranslation } from "react-i18next";
import "./Home.css";

export const TopPosts = ({ posts }) => {
    const { t } = useTranslation();

    return (
        <div className="box-container bordered">
                <div className="title">
                    {t("Top posts")}
                </div>
            {posts &&
                <div className="table-like-container">
                    <div className="row home-top-post header">
                        <div>{t("Title")}</div>
                        <div className="align-center">{t("Likes")}</div>
                    </div>
                    {posts.length > 0 && posts.map(p => <>
                        <div className="row home-top-post">
                            <div>
                                <h3 className="sc-post__title">{p?.title}</h3>
                                <span className="sc-post__meta">
                                    {t("Written by")}
                                    &nbsp;<strong>{p?.primaryAuthor}</strong>
                                    &nbsp;•&nbsp;<strong>{formatUtcToDateAndTime(p?.publishedAt || p?.createdAt)}</strong>
                                </span>
                            </div>
                            <div className="align-center">
                                <Icon icon="heart" />&nbsp;{p.likes}
                            </div>
                        </div>
                    </>)}
                    {posts.length === 0 &&
                        <div className="row align-center">
                            {t("No data found")}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default TopPosts;