export default {
  id: "9e18ed69-0a12-4ecf-b7b4-2c70c0ea7976",
  name: "Smart City Zadar",
  description: "neki description",
  tagLine: "neki tagline",
  status: 1,
  statusName: "Active",
  domains: ["zadar.hr"],
  validFrom: "2023-02-14T08:46:13.88Z",
  validUntil: "2023-02-16T08:46:13.882Z",
  logo: null,
  webAppTemplate: {
    templateId: "8fa9fabb-34c7-4c9f-9c60-ee3ba0979ee3",
    type: "web",
    lightmode: {
      primaryColor: "#579BB1",
      secondaryColor: "#E1D7C6",
      backgroundColor: "#ECE8DD",
      textColor: "#F8F4EA",
    },
    darkmode: {
      primaryColor: "#2C3639",
      secondaryColor: "#3F4E4F",
      backgroundColor: "#A27B5C",
      textColor: "#DCD7C9",
    },
  },
  mobileAppTemplate: {
    templateId: "33d4a717-aad4-44b2-a178-04ba91762f3c",
    type: "mobile",
    lightmode: {
      primaryColor: "#579BB1",
      secondaryColor: "#E1D7C6",
      menuColor: "#ECE8DD",
      footerColor: "#F8F4EA",
    },
    darkmode: {
      primaryColor: "#2C3639",
      secondaryColor: "#3F4E4F",
      menuColor: "#A27B5C",
      footerColor: "#DCD7C9",
    },
  },
  certificates: [
    {
      name: "nekiconfig.txt",
      url: "/app/Uploads/0505e146-d2e4-443f-9319-cc3542597913/a484d35d-2dc5-4998-a790-2043d9b31dee",
    },
    {
      name: "nekicert.txt",
      url: "/app/Uploads/0505e146-d2e4-443f-9319-cc3542597913/a1ae803c-5a77-47b6-9d41-883e82ed0cc7",
    },
  ],
  style: null,
  useStyle: false,
  configs: [
    {
      name: "nekiconfig.txt",
      url: "/app/Uploads/0505e146-d2e4-443f-9319-cc3542597913/182c0fdd-5606-4597-9a48-b0d987ec6629",
    },
  ],
  subscriptionFeatures: [
    //  {
    //    featureId: "2abb9f83-4bbc-436c-9461-897025c28028",
    //    subscriptionId: "9e18ed69-0a12-4ecf-b7b4-2c70c0ea7976",
    //    validFrom: "2023-02-14T08:46:13.88Z",
    //    validUntil: "2023-02-16T08:46:13.882Z",
    //    activated: true,
    //    featureName: "Ticketing",
    //    featureCode: "ticketing",
    //  },
    //  {
    //     "featureId":"4e270299-78f4-4a02-95be-5c7b168a7023",
    //     "subscriptionId":"9e18ed69-0a12-4ecf-b7b4-2c70c0ea7976",
    //     "validFrom":"2023-02-14T08:46:13.88Z",
    //     "validUntil":"2023-02-16T08:46:13.882Z",
    //     "activated":true,
    //     "featureName":"Payments",
    //     "featureCode": "payments"
    //  }
  ],
  vendors: [
    {
      id: "00000000-0000-0000-0000-000000000000",
      tenantId: "00000000-0000-0000-0000-000000000000",
      identifier: "vodovod",
      title: "Trgovačko društvo Vodovod d.o.o. Zadar",
      oib: "89406825003",
      address: "Špire Brusine 17, 23000 Zadar",
      logoUrl:
        "http://localhost:5026/api/file?isPublic=true&fileId=dd07c7e9-ad09-4ed4-9f9d-44330527b9b1",
      paymentShops: [
        {
          identifier: "paymentShop1",
          providerId: "providerID",
          iban: "HR123456789",
        },
      ],
    },
  ],
  merchantId: null,
  merchantIban: null,
  mainCurrency: null,
};
