import React, { useContext } from 'react';
import axios from 'axios';
import { ControlTitle, ControlError } from './index';
import { CombisSurveyContext } from '../Context';
import C from '../const';
import { useEffect } from 'react/cjs/react.development';

const styles = {
    lineHeight: '200px'
};

export const ControlUpload = ({ formValue, componentData: cd, readonly, error, onBlur, onChange, setFormData }) => {
    const { components, fileUploadUrl } = useContext(CombisSurveyContext);

    const Component = components[C.CONTROLS.FILE];
    
    const handleProgress = (fileKey, progressPercentage) => {
        const x = formValue.map(s => { 
            return s.fileKey !== fileKey ? s : {
                ...s,
                progressPercentage: Math.round(progressPercentage)
            };
        });
        onChange(x);
    }

    useEffect(() => {
        console.log('*** CHANGED', { formValue })
    }, [formValue]);

    const handleUpload = (data) => {
        var newFiles = data.map(d => {
            return {
                fileKey: d.fileKey,
                name: d.name,
                status: C.FILE_UPLOAD_STATUS.UPLOAD_IN_PROGRESS,
                size: d.blobFile.size,
                type: d.blobFile.type,
                progressPercentage: 0                    
        }});
        var newState = [...formValue || [], ...newFiles];
        onChange(newState);


        // setFormData(prevState => {
        //     var prevStateFiles = prevState[cd.name];
        //     {console.log('prevStateFiles', prevStateFiles)}
        //     if (!prevStateFiles)
        //     {
        //         return { ...prevState, [cd.name]: newFiles };
        //     }
        //     else {
        //         return { ...prevState, [cd.name]: [...prevState[cd.name], ...newFiles ] };                
        //     }
        // });
        
        // iako servis podržava upload multi-files zbog problema sa trackanjem upload % svakog file posebno radimo upload za svaki file posebno
        for(let i = 0; i < data.length; i++){
            let fd = new FormData();
            fd.append(`Files`, data[i].blobFile);
            
            axios.post(fileUploadUrl, fd, {
                    headers: {
                        'Content-Type' : 'multipart/form-data'
                    },
                    onUploadProgress: e => handleProgress(data[i].fileKey, ((e.loaded/data[i].blobFile.size) * 100))
                },                
            )
            .then(function (res) {
                const x = newState.map(s => { return s.fileKey !== data[i].fileKey ? s : {
                    ...s,
                    fileGuid: res.data.data[0].fileGuid,
                    status: C.FILE_UPLOAD_STATUS.UPLOAD_COMPLETED,
                    progressPercentage: 100
                }});

                onChange(x);
                onBlur(x);

                return(res.data);
            })
            .catch(function (error) {
                setFormData(prevState => {
                    const x = prevState[cd.name].map(s => { return s.fileKey !== data[i].fileKey ? s :{
                        ...s,
                        status: C.FILE_UPLOAD_STATUS.UPLOAD_FAILED,
                    }});
                    return {
                        ...prevState,
                        [cd.name]: x
                    }
                });
                console.log(error);
            });
        }
    }

    const handleRemove = (fileKey, name) => {
        const file = formValue.find(t => t.fileKey === fileKey);
        
        // ako nemamo fileGuid samo ga brišemo s liste
        // TODO: ovo koristimo dok ne napravimo brisanje na Storage.api
        if (true || !file.fileGuid){
            onChange(formValue.filter(t => t.fileKey !== fileKey));
            onBlur(formValue.filter(t => t.fileKey !== fileKey));
            return;
        }  

        axios.delete(`${fileUploadUrl}?filename=${name}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(function (res) {
            onChange(formValue.filter(t => t.fileKey !== fileKey));
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = `${fileUploadUrl}?FileId=${file.fileGuid}`;
        link.click();
        link.remove();
    }

    return (
    <div className="form-item">
        <ControlTitle title={cd.title} />
        <Component
            formValue={formValue}
            onChange={onChange}
            handleUpload={handleUpload}
            handleRemove={handleRemove}
            draggable={cd.draggable}
            allowMultiple={cd.allowMultiple}
            readonly={readonly}
            onDownload={handleDownload}
        >
            {cd.draggable &&
               <div style={styles}>Click or Drag files to this area to upload</div>
            }
        </Component>
        <ControlError error={error} />
    </div>);
}