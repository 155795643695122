import {Popover} from "@/Components/UI";
import { useEffect } from "react";

import { HexColorPicker, HexColorInput } from "react-colorful";
import "./ColorPicker.css";

const ColorPicker = ({value, onChange, ...props}) => {
    useEffect(() => {
        console.log("Color picker value:", props)
    }, [props])

    return (
        <div className="color-picker">
            <Popover 
                trigger="click"
                placement="auto" 
                style={{width:'250px'}}
                content={<HexColorPicker color={value || ''} onChange={onChange} {...props} />}
            >
                <div className="color-picker__swatch"><div style={{background: value, width:'100%', height:'100%'}}></div></div>
            </Popover>
            <HexColorInput color={value || ''} onChange={onChange} {...props} style={{ border: 0, width:'70%', outline: 'none'}} />
        </div>
    )
}

export default ColorPicker;