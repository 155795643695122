import React from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import routes from "@/routes";
import { Breadcrumb } from "rsuite";
import { useTranslation } from "react-i18next";

const Breadcrumbs = ({title, ...props}) => {
    const location = useLocation();
    //const history = useHistory();
    const params = useParams();
    const {t} = useTranslation();
    const extractRoutes = routes.filter(r => r.path).concat(routes.filter(r => r.children && r.children.length).map(r => r.children).flat());

    const crumbs = extractRoutes
            // Swap out any dynamic routes with their param values.
            // E.g. "/pizza/:pizzaId" will become "/pizza/1"
            .map(({ path, ...rest }) => ({
                path: Object.keys(params).length
                ? Object.keys(params).reduce(
                    (path, param) => path.replace(
                        `:${param}`, params[param]
                    ), path
                    )
                : path,
                ...rest
            }))
            // Get all routes that contain the current one.
            .filter(({ path }) => location.pathname == path || path != "/" && location.pathname.startsWith(path));

    //console.log("Crumbs:", location.pathname, crumbs, extractRoutes.filter(({ path }) => location.pathname == path || path != "/" && location.pathname.startsWith(path)), params)
    if (crumbs.length < 1) {
        return null;
    }

    const finalCrumbs = crumbs.length > 1 && [crumbs[0], crumbs[crumbs.length-1]] || crumbs;

    return (
        <Breadcrumb separator={'>'} className="sc-breadcrumbs">
        {/* Link back to any previous steps of the breadcrumb. */}
        {finalCrumbs.map(({ name, path }, key) =>
            key + 1 === finalCrumbs.length ? (
            <Breadcrumb.Item active key={`breadcrumb-${name}-${key}`} className="sc-breadcrumbs__item sc-breadcrumbs__item--active">
                {t(title || name)}
            </Breadcrumb.Item>
            ) : (
            <Breadcrumb.Item className="sc-breadcrumbs__item" componentClass={Link} key={`breadcrumb-${name}-${key}`} to={location?.state?.from ?? path}>
                {location?.state?.from && t("Go back") || t(name)}
            </Breadcrumb.Item>
            )
        )}
        </Breadcrumb>
    );
};

export default Breadcrumbs;