import { useState, useEffect, useContext, useRef } from "react";
import {
  Col,
  ControlLabel,
  SelectPicker,
  Modal,
  Loader,
  Placeholder,
  Tag,
} from "rsuite";
import { FlexboxGrid, Form, Button } from "@/Components/UI";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { alphaKorService, alphaIdService } from "@/Services";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { Schema } from "rsuite";

const { Paragraph, Grid } = Placeholder;
const { StringType } = Schema.Types;

const UserViewer = ({ oib, show, onClose, onSelect, ...props }) => {
  const { t } = useTranslation();

  const userModel = Schema.Model({
    oib: StringType().isRequired(`${t("OIB")} ${t("is required")}`),
    firstName: StringType().isRequired(
      `${t("First name")} ${t("is required")}`
    ),
    lastName: StringType().isRequired(`${t("Last name")} ${t("is required")}`),
    email: StringType().isEmail(t("Please enter a valid email address")),
  });

  const { errorHandler } = useContext(GlobalContext);
  const [customer, setCustomer] = useState();
  const [alphaIdUser, setAlphaIdUser] = useState();
  const [errors, setErrors] = useState();

  const [isLoading, setisLoading] = useState(false);
  const [user, setUser] = useState();
  const [oldUser, setOldUser] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef();

  const clearData = () => {
    setUser();
    setCustomer();
    setAlphaIdUser();
    setErrors();
    setHasUnsavedChanges(false);
  };

  const handleOpen = async () => {
    clearData();

    if (oib) {
      try {
        setisLoading(true);
        const [customerRes, userRes] = await Promise.all([
          alphaKorService
            .getCustomerByOib(oib)
            .catch((error) => console.error("Customer error:", error)),
          alphaIdService
            .getUserByOib(oib)
            .catch((error) => console.error("User error:", error)),
        ]);

        let finalUser = {};
        if (customerRes?.data && customerRes?.data.length) {
          setCustomer(customerRes.data[0]);
          finalUser = {
            ...finalUser,
            customerId: customerRes.data[0].uuid,
            oib: customerRes.data[0].oib,
            firstName: customerRes.data[0].firstName,
            lastName: customerRes.data[0].lastName,
            email: customerRes.data[0].email || "",
            phoneNumber:
              customerRes.data[0].mobile || customerRes.data[0].phone || "",
          };
        }

        if (userRes?.data) {
          setAlphaIdUser(userRes.data);
          finalUser = {
            ...finalUser,
            userId: userRes.data.id,
            oib: userRes.data.oib,
            firstName: userRes.data.firstName,
            lastName: userRes.data.lastName,
            email: userRes.data.email || "",
            phoneNumber: userRes.data.phoneNumber || "",
          };
        }

        setOldUser(finalUser);
        setUser(finalUser);
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error("Error while fetching customer and user data:", error);
        //errorHandler(error)
      } finally {
        setisLoading(false);
      }
    }
  };

  useEffect(() => {
    setHasUnsavedChanges(!_.isEqual(user, oldUser));
  }, [user]);

  const handleSelect = async () => {
    const checkResult = userModel.check(user || {});
    setErrors(checkResult);
    if (Object.entries(checkResult).every((t) => !t[1].hasError)) {
      try {
        let saveUser = { ...user };
        setIsSaving(true);
        if (user.userId && hasUnsavedChanges) {
          await alphaIdService.updateUser({
            id: user.userId,
            userData: { ...alphaIdUser, ...user },
          });
        }

        if (!user.userId) {
          const res = await alphaIdService.createUser({
            userData: { ...user, language: "hr", isEnabled: true },
          });
          saveUser = { ...saveUser, ...res?.data };
        }

        onSelect(saveUser);
        handleClose();
      } catch (error) {
        console.error("Error while creating/updating user:", error);
        errorHandler(error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleClose = () => {
    clearData();
    onClose();
  };

  return (
    <>
      <Modal
        id="openTicketModal"
        size="md"
        show={show}
        onHide={handleClose}
        onShow={handleOpen}
      >
        <Modal.Header>
          <Modal.Title>{(oib && t("View user")) || t("Add user")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoading && (
            <Paragraph rows={16}>
              <Loader size="md" backdrop vertical />
            </Paragraph>
          )) || (
            <>
              <Form
                ref={formRef}
                model={userModel}
                formValue={user}
                onChange={setUser}
                fluid
              >
                <FlexboxGrid>
                  <FlexboxGrid.Item componentClass={Col} sm={24}>
                    <Form.Textbox
                      disabled={oib}
                      name="oib"
                      title={t("OIB")}
                      errors={errors}
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item componentClass={Col} sm={24}>
                    <Form.Textbox
                      name="firstName"
                      disabled={oib}
                      title={t("Name")}
                      errors={errors}
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item componentClass={Col} sm={24}>
                    <Form.Textbox
                      name="lastName"
                      disabled={oib}
                      title={t("Surname")}
                      errors={errors}
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item componentClass={Col} sm={24}>
                    <Form.Textbox
                      name="email"
                      title={t("Email")}
                      errors={errors}
                    />
                  </FlexboxGrid.Item>

                  <FlexboxGrid.Item componentClass={Col} sm={24}>
                    <Form.Textbox
                      name="phoneNumber"
                      title={t("Contact phone number")}
                      errors={errors}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
        >
          <Button
            onClick={handleSelect}
            loading={isSaving}
            appearance="primary"
          >
            {(hasUnsavedChanges &&
              `${(oib && t("Update")) || t("Save")} ${t("and")} ${t(
                "Select"
              )}`) ||
              t("Select")}
          </Button>
          <Button appearance="subtle" onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserViewer;
