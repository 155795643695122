
export const pagingService = {
    getPageSizeOptions
};

async function getPageSizeOptions() {
    return [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 30, label: 30 }
    ];
}