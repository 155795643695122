import { createContext, useState, useEffect } from "react";
import { oidcClientService } from "@/Services/OidcClientService";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { languageService } from "../Services/LanguageService";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const history = useHistory();

  const [loginUser, setLoginUser] = useState(
    sessionStorage.getItem(`loginData`)
  );
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    // Add a request interceptor for set header
    axios.interceptors.request.use(async function (request) {
      const token = await oidcClientService.getAccessToken();
      request.headers.Authorization = `Bearer ${token}`;
      return request;
    });
  }, []);

  useEffect(() => {
    languageService
      .getLanguageByCultureCode(loginUser?.language)
      .then((res) => {
        setLanguage(res.data);
      });
  }, [loginUser?.language]);

  const handleLogin = async () => {
    try {
      await oidcClientService.login();
    } catch (err) {
      console.error("Login error:", err);
      history.push("/error?type=identity");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loginUser,
        setLoginUser,
        handleLogin,
        language,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
