import axios from "axios";
import { languageApiUrl } from "../Config/Url";

export const languageService = {
  getLanguages,
  getLanguageById,
  getLanguageByCultureCode,
  getTranslations,
  createTranslation,
  updateTranslation,
};

async function getLanguages() {
  return axios
    .get(languageApiUrl + "/languages")
    .then((res) => res)
    .catch((err) => err.response);
}

async function getLanguageById(id) {
  return axios
    .get(languageApiUrl + "/languages/" + id)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getLanguageByCultureCode(cultureCode) {
  return axios
    .get(languageApiUrl + "/languages/cultirecode/" + cultureCode)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getTranslations({
  languageId,
  typeId,
  page,
  take,
  searchText,
  sort,
  isDesc,
}) {
  const sortPrefix = isDesc ? "-" : "*";
  return axios
    .get(languageApiUrl + "/translations", {
      params: {
        languageId,
        typeId,
        page,
        take,
        searchText,
        sort: sortPrefix + sort,
      },
    })
    .then((res) => res)
    .catch((err) => err.response);
}

async function createTranslation({ key, value, typeId, languageId }) {
  return axios
    .post(languageApiUrl + "/translations", { key, value, typeId, languageId })
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateTranslation({ id, key, value }) {
  return axios
    .put(languageApiUrl + "/translations", { id, key, value })
    .then((res) => res)
    .catch((err) => err.response);
}
