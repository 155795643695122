import Oidc from "oidc-client";
import axios from "axios";
import { OidcConfig } from "../Config/OidcConfig";
import { loginUrl } from "../Config/Url";

//import { userRoleService} from "../Services/userRoleService";

export const oidcClientService = {
  login,
  logout,
  getUser,
  //getRole,
  signInSilent,
  getAccessToken,
  getApiData,
  signInSilentCallback,
  signInRedirectCallback,
  signOutRedirectCallback,
  completeAuthentication,
  getUserFromSessionStorage,
  getHeader,
  getAuthHeaderValue,
};

const userManager = new Oidc.UserManager(OidcConfig);
userManager.events.addUserSignedOut(async function (ev) {
  await logout();
});

function login() {
  let redirectData = {
    extraQueryParams: {
      admin: "showPassword",
    },
    state: sessionStorage.getItem("initialLocation"),
  };

  if (window && window.REACT_IS_PRODUCTION) {
    redirectData = {
      state: sessionStorage.getItem("initialLocation"),
    };
  }

  return userManager.signinRedirect(redirectData);
}

async function logout() {
  const user = await getUser();
  // userManager.removeUser();
  // userManager.clearStaleState();
  userManager
    .signoutRedirect({ id_token_hint: user && user.id_token })
    .then((suc) => console.log("Signed out successfully"))
    .catch((err) => console.log(err.response));
}

async function signInSilent() {
  return await userManager.signinSilent();
}

async function getUser() {
  const user = await userManager.getUser();
  if (!user) {
    try {
      user = await userManager.signinRedirectCallback();
    } catch (err) {
      console.log(err.response);
    }
  }
  return user;
}

/*
async function getRole() {
  const user = await getUser();
  const roles = userRoleService.getUserRoles(user.profile.name);
  return user && roles;//user.profile.role;
}
*/

async function getAccessToken() {
  const user = await getUser();
  if (user && user.expired === true) {
    try {
      const silentUser = await signInSilent();
      return silentUser && silentUser.access_token;
    } catch (err) {
      console.log("Error get access token:", err.response);
      await logout();
    }
  }
  return user && user.access_token;
}

async function getApiData(url) {
  const header = await getHeader();
  return await axios
    .get(url, header)
    .then(async (res) => res)
    .catch((err) => console.log(err.response));
}

async function getHeader() {
  const token = await getAccessToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

async function getAuthHeaderValue() {
  const token = await getAccessToken();
  const authHeaderValue = `Bearer ${token}`;
  return authHeaderValue;
}

async function signInSilentCallback() {
  userManager.signinSilentCallback().catch((err) => {
    console.log(err);
  });
  userManager.getUser().then((user) => {});
}

async function signInRedirectCallback() {
  const user = await userManager
    .signinRedirectCallback()
    .then((user) => {})
    .catch((err) => {
      console.log(err);
    });
}

async function signOutRedirectCallback() {
  userManager.signoutRedirectCallback().catch((err) => {
    console.log(err);
  });
}

async function completeAuthentication() {
  userManager.processSigninResponse().catch((err) => {
    console.log(err);
  });
}

function getUserFromSessionStorage() {
  return JSON.parse(
    sessionStorage.getItem(`oidc.user:${loginUrl}:sc-admin-web`)
  );
}
