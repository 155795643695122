import { useContext } from "react";
import { AuthContext } from "@/Contexts/AuthContext";
import { hasAuth } from "@/Helpers/AuthHelper";

const Permission = ({roles, claims, ...props}) => {
    const { loginUser } = useContext(AuthContext);
    return(
        hasAuth(loginUser, roles, claims) && props.children || null
    )
}

export default Permission;
