import axios from "axios";
import { ticketingApiUrl } from "@/Config/Url";
import { toQueryString } from "../Helpers/UrlHelper";
import { apiUrl, ticketsUrl } from "../Config/Url";

async function getTicketById(id) {
  return axios.get(`${ticketingApiUrl}/tickets/${id}`).then(res => res);
  //.catch((err) => err.response);
}

async function getTicketingStatuses() {
  return axios.get(`${ticketingApiUrl}/tickets/statuses`).then(res => res);
  //.catch((err) => err.response);
}

async function getTicketingPriorities() {
  return axios.get(`${ticketingApiUrl}/tickets/priorities`).then(res => res);
  //.catch((err) => err.response);
}

async function getOrganizations() {
  return axios.get(`${ticketingApiUrl}/tickets/assignable`).then(res => res);
  //.catch((err) => err.response);
}

async function getAssignable(search) {
  return axios
    .get(`${ticketingApiUrl}/tickets/assignable?${toQueryString(search)}`)
    .then(res => res);
  //.catch((err) => err.response);
}

async function getTicketingReport(data) {
  return await axios
    .get(`${ticketingApiUrl}/tickets/report`, {
      params: {
        createdByName: data.createdByName,
        name: data.name,
        assignedToId: data.assignedToId,
        priorityId: data.priorityId,
        statusId: data.ticketStatus,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        page: data.page,
        take: data.take,
      },
    })
    .then(res => res);
  //.catch((err) => err.response);
}

async function getTicketingReportStat(data) {
  return axios.get(`${ticketingApiUrl}/tickets/report-stat`, {
    params: {
      createdByName: data.createdByName,
      name: data.name,
      assignedToId: data.assignedToId,
      priorityId: data.priorityId,
      statusId: data.ticketStatus,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
    },
  });
}

const getCategories = async () => {
  let url = `/topic`;

  return axios.get(ticketingApiUrl + url, {}).then(res => res);
};

const getMainCategories = async () => {
  let url = `/topic/GetMainTopics`;

  return axios.get(ticketingApiUrl + url, {}).then(res => res);
};

const getCategoryById = async id => {
  let url = `/topic/${id}`;

  return axios.get(ticketingApiUrl + url, {}).then(res => res);
};

const createCategory = async data => {
  let url = `/topic`;
  return axios.post(ticketingApiUrl + url, data).then(res => res);
};

const updateCategory = async data => {
  let url = `/topic`;
  return axios.put(ticketingApiUrl + url, data).then(res => res);
};

const deleteCategory = async id => {
  let url = `/topic/${id}`;
  return axios.delete(ticketingApiUrl + url, {});
};
const updateTicketPriority = async data => {
  let url = `/tickets/${data.ticketId}/priority/${data.priorityId}`;

  return axios
    .put(ticketingApiUrl + url, {
      ticketId: data.ticketId,
      priorityId: data.priorityId,
    })
    .then(res => res);
  //.catch((err) => err.response);
};

const updateTicketStatus = async data => {
  let url = `/tickets/${data.ticketId}/status/${data.transitionId}`;

  return axios
    .put(ticketingApiUrl + url, {
      ticketId: data.ticketId,
      statusId: data.transitionId,
    })
    .then(res => res)
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
};

const assignTicket = async data => {
  let url = `/tickets/${data.ticketId}/assign/${data.assignToId}`;

  return axios
    .put(ticketingApiUrl + url, {
      ticketId: data.ticketId,
      assignToId: data.assignToId,
      assignToName: data.assignToName,
    })
    .then(res => res);
  //.catch((err) => err.response);
};

async function getTicketingCategories() {
  return [
    { name: "Svi", id: "" },
    { name: "Vodovod", id: "vod" },
    { name: "Vrtić", id: "vrtic" },
    { name: "Škola", id: "skola" },
    { name: "Komunalije", id: "kom" },
    { name: "Promet", id: "pro" },
    { name: "Gradsko oko", id: "oko" },
    { name: "Ostalo", id: "ost" },
  ];
}

async function rejectTicket(data) {
  return axios
    .put(`${ticketingApiUrl}/tickets/${data.ticketId}/reject`, data)
    .then(res => res);
}

async function resolveTicket(id) {
  let url = `/tickets/${id}/finish`;

  return axios.put(ticketingApiUrl + url).then(res => res);
  //.catch((err) => throw new Error(err.response);
}
// async function getTicketingPriorities() {
//     return [
//         { name: 'Svi', id: -1},
//         { name: "Very High", id: 1 },
//         { name: "High", id: 2 },
//         { name: "Normal", id: 3 },
//         { name: "Low", id: 4 },
//     ];
// }

const getOrganizationDependencies = async organizationId => {
  let url = `/tickets/organization/${organizationId}/dependencies`;

  return axios.get(ticketingApiUrl + url, {}).then(res => res);
};

async function getAttachments(ticketId) {
  return axios
    .get(`${ticketingApiUrl}/tickets/${ticketId}/attachments`)
    .then(res => res);
  //.catch((err) => err.response);
}

async function putTicketOnHold(ticketId) {
  return axios
    .put(`${ticketingApiUrl}/tickets/${ticketId}/hold`, {
      ticketId: ticketId,
    })
    .then(res => res);
}

async function returnTicketFromHold(ticketId) {
  return axios
    .put(`${ticketingApiUrl}/tickets/${ticketId}/recycle`, {
      ticketId: ticketId,
    })
    .then(res => res);
}

async function getColors() {
  return axios.get(`${ticketingApiUrl}/topic/colors`).then(res => res);
}

async function createTicket(data) {
  return axios.post(`${ticketingApiUrl}/tickets`, data).then(res => res);
}

async function updateTicket(ticketId, data) {
  return axios
    .post(`${ticketingApiUrl}/tickets/${ticketId}`, data)
    .then((res) => res);
}

async function changeTicketOwner({ ticketId, assignToId }) {
  return axios
    .put(`${ticketsUrl}/${ticketId}/change-owner/${assignToId}`)
    .then(res => res);
}

export default {
  getTicketById,
  getTicketingCategories,
  getTicketingStatuses,
  getTicketingPriorities,
  rejectTicket,
  resolveTicket,
  getOrganizations,
  updateTicketPriority,
  updateTicketStatus,
  assignTicket,
  getAssignable,
  getTicketingReport,
  getTicketingReportStat,
  getCategories,
  getMainCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  deleteCategory,
  getOrganizationDependencies,
  getAttachments,
  putTicketOnHold,
  returnTicketFromHold,
  getColors,
  createTicket,
  changeTicketOwner,
  updateTicket,
};
