import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import AppContainer from "./AppContainer";
import reportWebVitals from "./reportWebVitals";
import Loader from "@/Components/Loader";
import './i18n';
import routes from "@/routes";
import { oidcClientService } from "./Services/OidcClientService";
import { hasAuth } from "./Helpers/AuthHelper";

const user = oidcClientService.getUserFromSessionStorage()

if(!window.location.href.includes("/signin-oidc") && !window.location.pathname != "/admin" && !window.location.href.includes("/signout-oidc")) {
  const path = window.location.pathname.replace("/admin", "")
  const route = routes.find(r => r.path != "/" && path.includes(r.path));
  if(route) {
    sessionStorage.setItem("initialLocation", path)
  }
  
 
  /*
  if(route && user) {
    const authorized = hasAuth(user?.profile, route.roles, route.claims, route.path)
    console.log("Has auth:", route, user, authorized)
    if(authorized) {
      sessionStorage.setItem("initialLocation", path)
    }
  }
  */
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <AppContainer />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
