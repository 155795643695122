import { Table, Icon } from "rsuite";
import { useTranslation } from "react-i18next";
import { formatUtcToDateAndTime, formatUtcToDate } from "@/Helpers/DateHelper";
import { Button, Checkbox } from "@/Components/UI";
import "./Table.css";

const { Column, HeaderCell, Cell, Pagination } = Table;

const SCTable = ({
  data,
  columns,
  pagination,
  page,
  pageSize,
  totalItems,
  pageSizeMenu,
  onChangePage,
  onChangePageSize,
  isLoading,
  onRowClick,
  addNewClick,
  addNewTitle,
  isTree,
  error,
  noDataFallback,
  noDataFallbackText,
  firstColumnCheckbox,
  checkboxAllIndeterminate,
  onCheckboxAllSelected,
  isCheckboxAllSelected,
  onCheckboxSelected,
  isCheckboxSelected,
  ...props
}) => {
  const { t } = useTranslation();

  const NoDataComponent = ({onFallback, fallbackText, ...props}) => {
    return <>
      <div className="sc-table__no-data">
        {!error && t("No data found")}
        {error && <span className="sc-table__no-data__error">{error.errorMessage} <Button onClick={onFallback}>{fallbackText || t("Show all")}</Button></span>}
      </div>
    </>;
  }

  return (
    <>
      <Table
        wordWrap={!props.virtualized}
        className="sc-table"
        data={data}
        autoHeight={!props.height}
        loading={isLoading}
        onRowClick={onRowClick}
        isTree={isTree}
        renderEmpty={NoDataComponent}
        {...props}
      >
        {firstColumnCheckbox &&
          <Column
          >            
            <HeaderCell className="sc-table__header-cell">
              {onCheckboxAllSelected && isCheckboxAllSelected &&
                <Checkbox
                  inline
                  indeterminate={checkboxAllIndeterminate}
                  onChange={onCheckboxAllSelected}
                  checked={isCheckboxAllSelected}
                />
              }
            </HeaderCell>            
            <CheckboxCell onCheckboxSelected={onCheckboxSelected} isCheckboxSelected={isCheckboxSelected}  />
          </Column>
        }

        {columns &&
          columns.map((o, i) => {  
            return (
              <Column
                key={o.title}
                flexGrow={o.flexGrow}
                align={o.align}
                sortable={o.sortable}
                resizable={o.resizable}
                width={o.width}
              >
                <HeaderCell className={`sc-table__header-cell ${o.headerSize || ''}`}>
                  {t(o.title)}       
                </HeaderCell>
                {
                  (o.CustomCell && <CustomCell CustomComponent={o.CustomCell} dataKey={o.key} key={o.key} style={{ cursor: "pointer" }} />)
                  ||
                  (o.format && o.format === "date" && (
                    <DateCell style={{ cursor: "pointer" }} dataKey={o.key} key={o.key} />
                  )) ||
                  (o.format && o.format === "dateTime" && (
                    <DateTimeCell style={{ cursor: "pointer" }} dataKey={o.key} key={o.key} />
                  ))
                  || <TranslatedCell style={{ cursor: "pointer" }} dataKey={o.key} key={o.key} />
                }
              </Column>
            );
          })}
      </Table>
      {addNewTitle && addNewClick &&
        <span className="sc-table__add-new link__green" onClick={addNewClick}><Icon icon="plus" /> {addNewTitle}</span>
      }

      {pagination && (
        <Pagination
          lengthMenu={
            pageSizeMenu || [
              { value: 10, label: 10 },
              { value: 20, label: 20 },
              { value: 30, label: 30 },
              { value: 40, label: 40 },
              { value: 50, label: 50 },
            ]
          }
          activePage={page}
          displayLength={pageSize}
          total={totalItems}
          onChangePage={onChangePage}
          onChangeLength={onChangePageSize}
        />
      )}
    </>
  );
};

const CustomCell = ({ CustomComponent, dataKey, ...props }) => {
  return <Cell {...props}><CustomComponent value={props.rowData[dataKey]} rowData={props.rowData} dataKey={dataKey} /></Cell>;
};

const CheckboxCell = ({ onCheckboxSelected, isCheckboxSelected, ...props }) => {
  let checkboxChecked = isCheckboxSelected && isCheckboxSelected(props.rowData) || false;

  return <Cell {...props}>
    <Checkbox
      value={props.rowData}
      inline
      onChange={onCheckboxSelected}
      checked={checkboxChecked}
    />
  </Cell>
}

const TranslatedCell = ({ dataKey, ...props }) => {
  const { t } = useTranslation();

  return (
    <Cell {...props} className="sc-table__cell">{typeof props.rowData[dataKey] === 'string' && t(props.rowData[dataKey]) || props.rowData[dataKey]}</Cell>
  );
};

const DateTimeCell = ({ dataKey, ...props }) => {
  return (
    <Cell {...props}>{formatUtcToDateAndTime(props.rowData[dataKey])}</Cell>
  );
};

const DateCell = ({ dataKey, ...props }) => {
  return (
    <Cell {...props}>{formatUtcToDate(props.rowData[dataKey])}</Cell>
  );
};

export default SCTable;
