import {
  transactionStatusUrl,
  transactionsMonthUrl,
  vendorsUrl,
  transactionsUrl,
  transactionsTotalValueUrl,
  invoicesSepaUrl
} from "../Config/Url";
import axios from "axios";

export const invoiceAdminService = {
  getTransactionStatus, //Graf 1.
  getTransactionsByMonth, //Graf 2.
  getVendors,// Graf 3.
  getTransactions, //Tablica
  sepaExport
};



//const mockedTransactionList = {
//  data:{
//    "results": [
//        {
//            "id": "0a8860eb-2202-4531-bb3f-fa5394e7b7c7",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-17T12:03:22Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "34871594-d66f-4915-9715-0ff51f10619f",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-17T12:02:21Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "37e83151-1ecc-4e8d-b974-79a5a8c76354",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T08:55:41Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "5a92ac92-afd7-4219-8d21-b0a55d6fa53b",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T13:54:05Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "928ae95d-2a4f-4a56-a662-2ecad6b315f2",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T08:58:05Z",
//            "externalTransactionValue": 6.1,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "97f1e8db-2f1e-46d7-9166-e8d36bea7535",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T08:59:48Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "99f499b8-202a-4f14-8e3b-ae4e29e47e06",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T13:55:22Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "bb805a6e-be97-408b-b418-bfcfb65f18f9",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-15T18:49:15Z",
//            "externalTransactionValue": 6.1,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "c90371d0-b6c4-4da1-9498-fe6e864aa6e4",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-17T12:23:42Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//        {
//            "id": "caffd871-f62b-4faa-9aa4-151742806f3a",
//            "externalTransactionId": null,
//            "externalTransactionDate": "2022-08-16T11:11:16Z",
//            "externalTransactionValue": 23.48,
//            "isSuccessfull": false,
//            "errorDescription": "ODBIJENO",
//            "approvalCode": "",
//            "payerName": "NEBOJŠA PONGRAČIĆ",
//            "transactionDetailsDto": []
//        },
//  {
//            "id": "b96683c4-438b-4834-b4f8-3bdc216004ed",
//            "externalTransactionId": "710906d9-3a48-4852-8224-ea351516fb63",
//            "externalTransactionDate": "2022-08-19T16:04:59Z",
//            "externalTransactionValue": 138.19,
//            "isSuccessfull": true,
//            "errorDescription": "",
//            "approvalCode": "zbzCp9AWj37nvAOj0hf2bjYK8vvA",
//            "payerName": "NENO MARŠIĆ",
//            "transactionDetailsDto": [
//                {
//                    "id": "6948a62a-7b01-43da-acfc-76515ef05a01",
//                    "transactionId": "710906d9-3a48-4852-8224-ea351516fb63",
//                    "invoiceGuid": "c565e1d2-a983-4f24-a6f8-7a2fab0322ac",
//                    "invoiceNumber": "2210738517",
//                    "recipientName": "Vodovod i kanalizacija",
//                    "paidValue": "138.19",
//                    "paidDate": "2022-08-19T14:05:13.923202Z",
//                    "referenceModel": null,
//                    "description": null
//                }
//            ]
//        },
//        {
//            "id": "fe0dc4e2-43b6-4dfc-b330-f6726321f9ad",
//            "externalTransactionId": "d1f6f07c-ebd4-4546-8a4b-12405b28ba23",
//            "externalTransactionDate": "2022-08-22T08:12:40Z",
//            "externalTransactionValue": 18.61,
//            "isSuccessfull": true,
//            "errorDescription": "",
//            "approvalCode": "vj0pbnAIYj0fWA4d3zU0bUvGzS4A",
//            "payerName": "ŽELJANA BURAZIN",
//            "transactionDetailsDto": []
//        }
//    ],
//    "total": 12
//}};

//1.Graf - Ovo za sad više ne koristimo

async function getTransactionStatus(dateFrom, dateTo) {
  return axios
    .get(transactionStatusUrl, {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    })
    .then((res) => res);
}

//2. Graf
async function getTransactionsByMonth(dateFrom, dateTo) {
  return axios
   .get(transactionsMonthUrl, {
     params: {
       dateFrom: dateFrom,
       dateTo: dateTo,
     },
   })
   .then((res) => res);
  }

//3. Graf
async function getVendors(dateFrom, dateTo) {
  return axios
    .get(vendorsUrl, {
      params: {
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    })
    .then((res) => res);
}

//Tablica
async function getTransactions(dateFrom, dateTo, transactionsStatus, page, take, vendorId, paymentName, payerName, sortString) {
  //return mockedTransactionList;
  return axios
   .get(transactionsUrl, {
     params: {
       dateFrom: dateFrom,
       dateTo: dateTo,
       showUnSuccessfull: transactionsStatus != null ? !transactionsStatus : null,
       page: page,
       take: take,
       vendorId: vendorId,
       paymentName: paymentName,
       payerName:payerName,
       sort:sortString
     },
   })
   .then((res) => res);
}

async function sepaExport({vendorOib, dateFrom, dateTo}) {
  return axios({
      url: `${invoicesSepaUrl}`,
      responseType: "blob",
      params: {
        vendorOib,
        dateFrom: dateFrom,
        dateTo: dateTo,
      },
    });
}