import { DateRangePicker } from "rsuite";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const SCDateRangePicker = (props) => {

  const hrLocale = {
    sunday: "Ne",
    monday: "Po",
    tuesday: "Ut",
    wednesday: "Sr",
    thursday: "če",
    friday: "Pe",
    saturday: "Su",
    ok: "OK",
    today: "Danas",
    yesterday: "Jučer",
    hours: "Sati",
    minutes: "Minute",
    seconds: "Sekunde",
    last7Days: "Zadnjih 7 dana",
  };

  const enLocale = {
    sunday: "Su",
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    ok: "OK",
    today: "Today",
    yesterday: "Yesterday",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
  };

  return (
    <DateRangePicker {...props} format={props.locale == "hr" && "DD.MM.YYYY" || "YYYY-MM-DD"} locale={props.locale == "hr" && hrLocale || enLocale}>
      {props.children}
    </DateRangePicker>
  );
};

export default SCDateRangePicker;
