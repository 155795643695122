import React, { useState, useEffect, useContext } from "react";
import { FlexboxGrid } from "rsuite";
import { useTranslation } from "react-i18next";
import PaymentsBarChart from "./Dashboard/PaymentsBarChart";
import PaymentsPieChart from "./Dashboard/PaymentsPieChart";
import { ParentSize } from '@visx/responsive';
import {Col} from "rsuite";
import { AuthContext } from "@/Contexts/AuthContext";

const pieChartMargin = { top: 20, right: 20, bottom: 20, left: 20 };
const barChartMargin = { top: 50, right: 20, bottom: 20, left: 20 };

const PaymentsDashboard = ({ statisticsByMonth, statisticsInYear }) => {
  const { t } = useTranslation();
  const [pieChartData, setPieChartData] = useState([]);
  const [total, setTotal] = useState([]);
  const { loginUser, handleLogin } = useContext(AuthContext);

  useEffect(() => {
    if (statisticsInYear) {
      setPieChartData(_setPieChartData());
      setTotal(statisticsInYear.totalServices);
    }
  }, []);

  useEffect(() => {
    if (statisticsInYear) {
      setPieChartData(_setPieChartData());
      setTotal(statisticsInYear.totalServices);
    }
  }, [statisticsByMonth,statisticsInYear, loginUser.language]);


  const _setPieChartData = () => {
    const data = [];
    statisticsInYear?.serviceInfo?.map( item => {
      data.push({label: item.name, usage: item.value});
    } );    
    return data;
  };

  return (
    <FlexboxGrid justify="space-between" /*  style={{gap: "4rem"}} */>
      <FlexboxGrid.Item componentClass={Col} sm={12}>
        <ParentSize>
           {(parent) => (
              <PaymentsPieChart
                width={parent.width}
                height={parent.width / 1.78}
                parentTop={parent.top}
                parentLeft={parent.left}
                // this is the referer to the wrapper component
                parentRef={parent.ref}
                // this function can be called inside MySuperCoolVisxChart to cause a resize of the wrapper component
                resizeParent={parent.resize}
                margin={pieChartMargin}
                data={pieChartData}
                total={total}
              />
           )}
        </ParentSize>
        
      </FlexboxGrid.Item>
      {/*** --- Statistics By Month --- ***/}
      <FlexboxGrid.Item  componentClass={Col} sm={12}>
      <ParentSize>
           {(parent) => (
          <PaymentsBarChart
            width={parent.width}
            height={parent.width / 1.78}
            parentTop={parent.top}
            parentLeft={parent.left}
            // this is the referer to the wrapper component
            parentRef={parent.ref}
            // this function can be called inside MySuperCoolVisxChart to cause a resize of the wrapper component
            resizeParent={parent.resize}
            margin={barChartMargin}
            invoices={statisticsByMonth}
          />
          )}
        </ParentSize>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default PaymentsDashboard;
