import { useState, useEffect, useContext } from "react";
import { Page } from "@/Components/Layout";
import {
  Button,
  FlexboxGrid,
  Dropdown,
  IconButton,
  Icon,
} from "@/Components/UI";
import { useTranslation } from "react-i18next";
import { useHistory, NavLink } from "react-router-dom";
import {
  getOrganizations,
  getOrganizationGraph,
} from "../../Services/OrganizationStructureService";
import Table from "@/Components/UI/Table";
import OrganizationStructureGraph from "./OrganizationStructureGraph";
import { ParentSize } from "@visx/responsive";
import { GlobalContext } from "@/Contexts/GlobalContext";
import { useOrganizations } from "@/Hooks";
import OrgChart from "./OrgChart";

const OrganizationStructure = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, setLoading, errorHandler } = useContext(GlobalContext);
  const [organizationWithMembers, setOrganizationWithMembers] = useState([]);
  //const [loadingGraph, setLoadingGraph] = useState(false)

  //const { data: organizations, loading: organizationsLoading } = useOrganizations({page, take: pageSize, search});
  const [organizations, setOrganizations] = useState([]);

  const handleRowClick = (data) => {
    history.push("/organization/editor/" + data.id);
  };

  const columns = [
    {
      title: t("Code"),
      key: "code",
      resizable: true,
      align: "left",
      width: 150,
      CustomCell: ({ value, rowData }) => (
        <>
          {(rowData["parentCode"] && `${rowData["parentCode"]}/${value}`) ||
            value}
        </>
      ),
    },
    {
      title: t("Organization name"),
      key: "name",
      resizable: true,
      width: 350,
      CustomCell: ({ value, rowData }) =>
        (rowData["type"] != "Member" && (
          <NavLink to={"/organization/editor/" + rowData["id"]}>
            {value}
          </NavLink>
        )) ||
        value,
    },
    {
      title: t("Type"),
      key: "type",
      resizable: true,
      width: 200,
      align: "left",
    },
    {
      title: t("Valid from"),
      key: "validFrom",
      format: "date",
      align: "left",
      resizable: true,
      width: 200,
    },
    {
      title: t("Valid to"),
      key: "validTo",
      format: "date",
      align: "left",
      resizable: true,
      width: 200,
    },
    {
      title: t("Action"),
      resizable: true,
      width: 100,
      CustomCell: ({ rowData }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          {(rowData["type"] == "Organization" && (
            <IconButton
              appearance="subtle"
              circle
              onClick={() => handleRowClick(rowData)}
              icon={<Icon icon="edit2" />}
            />
          )) ||
            null}
          {/*<IconButton appearance="subtle" circle onClick={() => handleConfirmRemoveMember(rowData)} icon={<Icon icon="trash" />} />*/}
        </div>
      ),
    },
    /*
        {
            title: t("Number of members"),
            key: "numberOfMembers",
            resizable: true,
            width: 150
        },
        */
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const dasboardResult = await getOrganizationGraph();
        if (dasboardResult && dasboardResult.length) {
          setOrganizations(dasboardResult[0].children);
          setOrganizationWithMembers(dasboardResult[0]);
        }
      } catch (error) {
        console.error("Error while fetching organization graph:", error);
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const margin = {
    top: 60,
    right: 60,
    bottom: 60,
    left: 60,
  };

  /*
    const orgChart = <ParentSize>
    {parent => (
         <OrganizationStructureGraph 
            parentWidth={parent.width}
            parentHeight={parent.height}
            parentTop={parent.top}
            parentLeft={parent.left}
            // this is the referer to the wrapper component
            parentRef={parent.ref}
            // this function can be called inside MySuperCoolVisxChart to cause a resize of the wrapper component
            resizeParent={parent.resize}
            data={organizationWithMembers} 
          
            margin={margin} 
        />
    )}</ParentSize>
    */

  return (
    <Page style={{ width: "100%" }}>
      <Page.Header>
        <Button
          appearance="primary"
          onClick={() => history.push("/organization/add")}
        >
          {t("New organization")}
        </Button>
        {/* <Dropdown title={t("All categories")} options={categoryOptions} labelKey="name" valueKey="id" onSelect={handleChangeCategory} />
                <Dropdown title={t("All statuses")} options={statusOptions} labelKey="name" valueKey="id" onSelect={handleChangeStatus} /> 
                <Dropdown title={t("Sort by: Newest")} options={sortingOptions} />  */}
      </Page.Header>

      {/* 
            {organizationWithMembers && orgChart && (
                <div style={{width:"100%", height: '500px', marginBottom: '2rem', opacity: loading ? '0.7' : '1'}}>
                    {orgChart}
                </div>
            ) || null}
            */}

      {(organizationWithMembers && !loading && (
        <OrgChart data={[organizationWithMembers]} />
      )) ||
        null}

      <Table
        isTree
        rowKey="id"
        data={organizations || []}
        /*data={organizations && organizations.results || []}
                totalItems={organizations && organizations.total || 0} }*/
        pagination={false}
        /*
                page={page} 
                pageSize={pageSize} 
                pageSizeMenu={pageSizeOptions}*/
        columns={columns}
        rowHeight={60}
        //isLoading={organizationsLoading}
        /*
                onChangePage={handleChangePage} 
                onChangePageSize={handleChangePageSize}*/
        //onRowClick={handleRowClick}
        renderTreeToggle={(icon, rowData) => {
          if (!rowData.children || rowData.children.length === 0) {
            return null;
          }

          return icon;
        }}
      />
    </Page>
  );
};

export default OrganizationStructure;
