
import {Avatar, Icon} from "@/Components/UI";
import {Tag} from "rsuite";
import { useTranslation } from "react-i18next";
import "./UserInfo.css";

const UserInfo = ({user, size, ...props}) => {
    const {t} = useTranslation();
    console.log("Got user for info:", user)

    return (
        user && (
            <section className="member">
         
                <div className="member__avatar">
                    <Avatar size={size} circle user={user} />
                </div>
 
                <div className="member__info">
                    <h3 className="member__name">{user.displayName || ''}</h3>
                    {user.email && <p className="member__email" title={t('Email')}>{user.email || ''}</p> || null}
                    {user.phoneNumber && <p className="member__phone" title={t('Phone')} style={{marginTop: 0}}><Icon icon="phone" /> {user.phoneNumber}</p> || null}
                    {/* {user.mobile && <p className="member__mobile"><Icon icon="mobile" /> {user.mobile}</p> || null} */}
                    {user.userType && user.userType == "O" && t("Trade") || user.userType == "P" && t("Legal")  || user.userType == "F" && t("Person") || null}
                    {/*<p className="member__created">{t('User since')} {user.createdAt && formatUtcToDate(user.createdAt) || '-'}</p>*/}
                </div>
        </section>
        ) || null
        
    )
}

export default UserInfo;