import { Button } from 'rsuite';
import "./Button.css";

const SCButton = ({appearance, ...props}) => {

    const classNameAppearance = appearance == "primary" && 'sc-btn--primary' ||
        appearance == "outline" && 'sc-btn--outline' ||
        appearance == "subtle" && 'sc-btn--subtle' ||
        appearance == "danger" && 'sc-btn--danger' ||
        appearance == "trigger" && 'sc-btn--trigger' ||
        '';

    const shimAppearance = appearance == "danger" && "primary" || appearance =="outline" && "ghost" || appearance; 

    return (
        <Button appearance={shimAppearance} {...props} className={`sc-btn ${classNameAppearance} ${props.className || ''}`}>{props.children}</Button>
    );
}

export default SCButton;