import React from "react";
import PageHeader from "./PageHeader";
import PageContent from "./PageContent";
import "./Page.css";
import {ErrorBoundary} from 'react-error-boundary';
import { ErrorFallback } from "@/Pages";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page = ({fluid, wide, hasUnsavedChanges, ...props}) => {
  const {t} = useTranslation();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={`sc-page${fluid && ' sc-page--fluid' || ''}${wide && ' sc-page--wide' || ''}`}>
        {props.children}
      </div>
      <Prompt when={hasUnsavedChanges || false} message={t("You have unsaved changes, are you sure you want to leave this page?")} />
    </ErrorBoundary>
  );
};

Page.Header = PageHeader;
Page.Content = PageContent;

export default Page;