import Pie from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { useTranslation } from "react-i18next";
import { Group } from "@visx/group";
import { GradientTealBlue } from "@visx/gradient";
import browserUsage, {
  BrowserUsage as Browsers,
} from "@visx/mock-data/lib/mocks/browserUsage";
import { PatternLines, PatternCircles, PatternWaves } from "@visx/pattern";
import { ControlSelect } from "../../CombisForm/Controls";

const browserNames = Object.keys(browserUsage[0]).filter(k => k !== "date");

// accessor functions
const usage = d => d.usage;

const colors = [
  "rgb(76, 110, 219)",
  "rgb(110, 64, 170)",
  "rgb(191, 60, 175)",
  "rgb(254, 75, 131)",
  "rgb(255, 120, 71)",
  "rgb(226, 183, 47)",
  "rgb(175, 240, 91)",
  "rgb(82, 246, 103)",
  "rgb(29, 223, 163)",
  "rgb(35, 171, 216)",
];

// color scales
const getBrowserColor = scaleOrdinal({
  domain: browserNames,
  range: ["rgba(255,255,255,0.7)", "rgba(255,255,255,0.6)"],
});

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };
const legendWidth = 270;

export const DashboardPieChart = ({
  data,
  width,
  height,
  margin = defaultMargin,
}) => {
  const { t } = useTranslation();

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;
  const glyphSize = 20;

  const ordinalColorScale = scaleOrdinal({
    domain: data.map(t => `${t.label} ${t.usage}`),
    range: data.map(t => getBrowserColor(t.pattern)),
  });

  const chartMarginLeft = () => {
    let x = innerWidth - legendWidth;
    let min1 = x / 2 + radius;
    let min2 = x - radius;
    var min = Math.min(min1, min2);

    return min > radius ? min : radius;
  };

  return (
    <>
      {data && (
        <>
          <div
            style={{
              position: "relative",
              height: `${height}px`,
              width: `${width}px`,
            }}>
            <div
              style={{
                padding: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                position: "absolute",
                right: "0",
                bottom: "0",
                margin: "0 10px 10px 0",
                borderRadius: "10px",
                width: `${legendWidth}px`,
              }}>
              {data.map(t => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0px",
                  }}>
                  <div style={{ color: "white" }}>
                    {t.label} - {t.usage}
                  </div>
                  <svg width={glyphSize} height={glyphSize}>
                    {t.pattern.type == "PatternLines" && (
                      <PatternLines
                        id={`pattern-${t.id}`}
                        orientation={t.pattern.orientation}
                        height={t.pattern.height || 8}
                        width={t.pattern.width || 8}
                        stroke={t.pattern.stroke || "white"}
                        strokeWidth={t.pattern.strokeWidth || 1}
                        background={t.pattern.background || "transparent"}
                      />
                    )}
                    {t.pattern.type == "PatternCircles" && (
                      <PatternCircles
                        id={`pattern-${t.id}`}
                        height={t.pattern.height || 8}
                        width={t.pattern.width || 8}
                        fill={t.pattern.fill || "white"}
                        stroke={t.pattern.stroke}
                        background={t.pattern.background || "transparent"}
                      />
                    )}
                    {t.pattern.type == "PatternWaves" && (
                      <PatternWaves
                        id={`pattern-${t.id}`}
                        height={t.pattern.height || 10}
                        width={t.pattern.width || 10}
                        fill={t.pattern.fill || "transparent"}
                        stroke={t.pattern.stroke || "white"}
                        strokeWidth={t.pattern.strokeWidth || 1}
                        background={t.pattern.background || "transparent"}
                      />
                    )}
                    <rect
                      fill={`url('#pattern-${t.id}')`}
                      width={glyphSize}
                      height={glyphSize}
                    />
                  </svg>
                </div>
              ))}
            </div>

            <svg width={width} height={height}>
              <GradientTealBlue id="teal" />
              <rect width={width} height={height} fill="url(#teal)" rx={3} />
              <Group
                top={centerY + margin.top}
                left={chartMarginLeft() + margin.left}>
                {data.reduce((a, v) => a + v.usage, 0) === 0 && (
                  <text fill="white" fontSize={18} textAnchor="middle">
                    {t("No open tickets")}
                  </text>
                )}
                <Pie
                  data={data}
                  pieValue={usage}
                  outerRadius={radius}
                  innerRadius={radius - donutThickness}
                  cornerRadius={3}
                  padAngle={0.005}>
                  {pie => {
                    return pie.arcs.map((arc, index) => {
                      const { usage, id } = arc.data;
                      const [centroidX, centroidY] = pie.path.centroid(arc);
                      const hasSpaceForLabel =
                        arc.endAngle - arc.startAngle >= 0.1;
                      const arcPath = pie.path(arc);
                      const arcFill = getBrowserColor(usage);
                      return (
                        <>
                          <g key={`arc-${id}`}>
                            <path d={arcPath} fill={`url('#pattern-${id}')`} />
                          </g>
                        </>
                      );
                    });
                  }}
                </Pie>
              </Group>
            </svg>
          </div>
        </>
      )}
    </>
  );
};
