import axios from "axios";
import { siteUrl } from "../Config/Url";

const removeBackendCookie = async cookie => {
  return axios
    .get(`${siteUrl}/api/removeCookie?cookie=${cookie}`)
    .then(res => res)
    .catch(err => err.response);
};

export const nextServices = {
  removeBackendCookie,
};
