import { postStatusesUrl, pageStatusesUrl } from "../Config/Url";
import axios from "axios";

export const statusService = {
    getPostStatusOptions,
    getPageStatusOptions
};

async function getPostStatusOptions() {
    return axios
    .get(postStatusesUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function getPageStatusOptions() {
    return axios
    .get(pageStatusesUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

/* async function getPageStatuses() {
    return [
        { text: "Drafts", value: "drafts" },
        { text: "Scheduled", value: "scheduled" },
        { text: "Published", value: "published" }
    ];
} */