import { postsUrl } from "../Config/Url";
import axios from "axios";

export const postService = {
  getPosts,
  getPost,
  createOrUpdatePost,
  deletePost,
  publishPost,
  unpublishPost,
};

async function getPosts(
  page,
  pageSize,
  postStatus,
  postTag,
  isFromExternalSource,
  postSorting,
  language,
) {
  var url = postsUrl;
  var params = {
    page: page,
    take: pageSize,
    tagId: postTag,
    sort: postSorting,
    language: language,
  };

  if (isFromExternalSource) {
    url += "/externals";
    params.sort = params.sort + "UpdatedAt";
  } else {
    switch (postStatus) {
      case 1:
        url += "/drafts";
        params.sort = params.sort + "UpdatedAt";
        break;
      case 2:
        url += "/scheduled";
        params.sort = params.sort + "UpdatedAt";
        break;
      case 3:
        url += "/published";
        params.sort = params.sort + "PublishedAt";
        break;
      default:
        params.sort = params.sort + "UpdatedAt";
    }
  }

  return axios.get(url, { params: params });
}

async function getPost(id, countryCode) {
  return axios.get(postsUrl + "/" + id + "?countryCode=" + countryCode);
}

async function createOrUpdatePost(postId, data) {
  if (postId) {
    return axios.put(postsUrl, { id: postId, ...data });
  }

  return axios.post(postsUrl, data);
}

async function deletePost(postId) {
  return axios.delete(postsUrl + "/" + postId);
}

async function publishPost(postId, publishDate, sendNotification) {
  if (postId) {
    return axios.put(postsUrl + "/publish/" + postId, {
      id: postId,
      publishDate: publishDate,
      sendNotification,
    });
  }
}

async function unpublishPost(postId) {
  if (postId) {
    return axios.put(postsUrl + "/unpublish/" + postId);
  }
}
