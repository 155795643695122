import { useContext, useEffect } from "react";
import { oidcClientService } from "@/Services/OidcClientService";
import { useHistory } from "react-router-dom";
import { AuthContext } from "@/Contexts/AuthContext";
import Loader from "@/Components/Loader";
import axios from "axios";
import { loginUrl } from "../../Config/Url";

function SigninOidc() {
  const history = useHistory();
  const { setLoginUser } = useContext(AuthContext);

  useEffect(() => {
    async function signinAsync() {
      const initialUser = await oidcClientService.getUser();

      if (initialUser && initialUser.profile) {
        const loginUser = await axios.get(
          `${loginUrl}/api/UserDetails?oib=${initialUser.profile.oib}`,
          {
            headers: { Authorization: `Bearer ${initialUser.access_token}` },
          },
        );

        setLoginUser({
          tenantId: "0505e146-d2e4-443f-9319-cc3542597913",
          ...loginUser.data.claims,
          id: loginUser.data.claims.sub,
          accessToken: loginUser.data.access_token,
          displayName: `${loginUser.data.claims.firstName} ${loginUser.data.claims.lastName}`,
        });
      }

      //console.log("Got login user:", loginUser);
      var prevLocation = sessionStorage.getItem("location");
      if (prevLocation && prevLocation.indexOf("signout-oidc") === -1)
        // ne smije redirectati na signout
        history.push(prevLocation);
      else history.push("/");
    }

    signinAsync();
  }, []);

  return <Loader />;
}

export default SigninOidc;
