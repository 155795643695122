import de from 'date-fns/locale/de';

const Calendar = {
  sunday: 'Ned',
  monday: 'Pon',
  tuesday: 'Uto',
  wednesday: 'Sri',
  thursday: 'Čet',
  friday: 'Pet',
  saturday: 'Sub',
  ok: 'OK',
  today: 'Danas',
  yesterday: 'Jučer',
  hours: 'Sati',
  minutes: 'Minuta',
  seconds: 'Sekundi',
  formattedMonthPattern: 'MMM, yyyy',
  formattedDayPattern: 'dd MMM, yyyy',
  dateLocale: de 
};

export default {
  common: {
    loading: 'Učitavanje...',
    emptyMessage: 'Nema podataka'
  },
  Plaintext: {
    unfilled: 'Neispunjeno',
    notSelected: 'Nije odabrano',
    notUploaded: 'Nije učitano'
  },
  Pagination: {
    more: 'Više',
    prev: 'Prethodno',
    next: 'Sljedeće',
    first: 'Prva',
    last: 'Zadnja',
    limit: '{0} / stranica',
    total: 'Ukupno redaka: {0}',
    skip: 'Skoči na{0}'
  },
  TablePagination: {
    lengthMenuInfo: '{0} / stranica',
    totalInfo: 'Ukupno: {0}'
  },
  Calendar,
  DatePicker: {
    ...Calendar
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: 'Zadnjih 7 Dana'
  },
  Picker: {
    noResultsText: 'Nema rezultata',
    placeholder: 'Odaberi',
    searchPlaceholder: 'Pretraga',
    checkAll: 'Sve'
  },
  InputPicker: {
    newItem: 'Novi zapis',
    createOption: 'Stvori novi zapis "{0}"'
  },
  Uploader: {
    inited: 'Početak',
    progress: 'Učitavanje',
    error: 'Greška',
    complete: 'Završeno',
    emptyFile: 'Prazno',
    upload: 'Učitaj'
  },
  CloseButton: {
    closeLabel: 'Zatvori'
  },
  Breadcrumb: {
    expandText: 'Prikaži putanju'
  },
  Toggle: {
    on: 'Otvori',
    off: 'Zatvori'
  }
};