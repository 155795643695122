import { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
const id = Date.now();
const SCSelectPicker = props => {
  const [container, setContainer] = useState();

  useEffect(() => {
    setContainer(document.getElementById(id)?.closest(".rs-modal-body"));
  }, []);

  return (
    <SelectPicker id={id} container={container} {...props}>
      {props.children}
    </SelectPicker>
  );
};

export default SCSelectPicker;
