import { createContext, useState } from "react";
export const HelpContext = createContext();

const HelpContextProvider = (props) => {
  const [imagesPreview, setImagesPreview] = useState()
  const [pageSections, setPageSections] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <HelpContext.Provider value={{ imagesPreview, setImagesPreview, pageSections, setPageSections, currentPage, setCurrentPage }}>
      {props.children}
    </HelpContext.Provider>
  );
};

export default HelpContextProvider;
