import { navigationUrl } from "../Config/Url";
import axios from "axios";

export default {
  createOrUpdateNavigation,
  createOrUpdateUserPortalNavigation,
  createOrUpdateSecondaryNavigation,
  deleteNavigationItem,
  getNavigation,
};

async function getNavigation() {
  return axios.get(navigationUrl).then(res => res);
}

async function createOrUpdateNavigation(data) {
  return axios.post(navigationUrl, { navigation: data }).then(res => res);
}

async function createOrUpdateUserPortalNavigation(data) {
  return axios
    .post(navigationUrl + "/user", { navigation: data })
    .then(res => res);
}

async function createOrUpdateSecondaryNavigation(data) {
  return axios
    .post(navigationUrl + "/secondary", { navigation: data })
    .then(res => res);
}

async function deleteNavigationItem(navigationId) {
  return axios.delete(navigationUrl + "/" + navigationId).then(res => res);
}
