import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce';
import { Page } from "@/Components/Layout";
import { Button, Input, Table } from "@/Components/UI";
import { FormControl, Icon, InputGroup } from 'rsuite';
import { alphaIdService } from '@/Services';

const SEARCH_KEY = "users-search";

const Users = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const getSearch = () => {
    return sessionStorage.getItem(SEARCH_KEY) && JSON.parse(sessionStorage.getItem(SEARCH_KEY)) || null
  }

  const storeSearch = () => {
      const storedSearch = getSearch();
      const newSearch = { ...storedSearch, searchText, page, pageSize };
      sessionStorage.setItem(SEARCH_KEY, JSON.stringify(newSearch))
  }

  const searchData = getSearch();

  const [page, setPage] = useState(searchData?.page || 1);
  const [pageSize, setPageSize] = useState(searchData?.pageSize || 10);
  const [searchText, setSearchText] = useState(searchData?.searchText || null);
  const [searchInputText, setSearchInputText] = useState(searchData?.searchText || null);
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    alphaIdService.getUsers(page, pageSize, searchText)
    .then(res => setUsers(res.data))
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {    
    storeSearch();
    fetchUsers();
  }, [page, pageSize]);

  useEffect(() => {
    if (searchText === searchData?.searchText)
      return;

    if (page > 1)  
      setPage(1)
    else {
      storeSearch();
      fetchUsers();
    };
  }, [searchText]);

  useEffect(() => {
    debouncedSearchHandler(searchInputText);
  }, [searchInputText]);

  const handleOnSearchInputChange = (value) => {
    setSearchInputText(value);
  }

  const handleSearchTextChange = (value) => {
    setSearchText(value);
  }

  const debouncedSearchHandler = useMemo(
    () => debounce(handleSearchTextChange, 400)
  , []);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: t("Name"),
      key: "firstName",
      flexGrow: 2,
    },
    {
      title: t("Surname"),
      key: "lastName",
      flexGrow: 2,
    },
    {
      title: t("OIB"),
      key: "oib",
      flexGrow: 2,
    },
    
    {
      title: t("Status"),
      key: "isEnabled",
      CustomCell: ({ value }) => <>{value ? t("Active") : t("Inactive")}</>,
      flexGrow: 1,
      align: "center",
    },
    {
      title: t("Email"),
      key: "email",
      flexGrow: 3,
    },
  ];

  return (
    <Page>
      <Page.Header>
        <InputGroup inside>
          <Input value={searchInputText} placeholder={t("Search...")} onChange={handleOnSearchInputChange} />
          <InputGroup.Addon>
            {!searchInputText && <Icon icon="search" />}
            {searchInputText && <Icon icon="close" style={{ cursor: 'pointer'}} onClick={() => {
              setSearchInputText('');
              setSearchText('');
            }} />}
          </InputGroup.Addon>
        </InputGroup>
        <Button
          appearance="primary"
          onClick={() => history.push("/users/editor")}
        >
          {t("New user")}
        </Button>
      </Page.Header>

      <Table
        data={users?.data}
        isLoading={loading}
        totalItems={users?.total}
        pagination={true}
        page={page}
        pageSize={pageSize}
        columns={columns}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        onRowClick={(rowData) => history.push(`/users/editor/${rowData.id}`)}
      />
    </Page>
  );
};

export default Users;
