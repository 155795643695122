import React from "react";
import { Grid } from "rsuite";
import "./PageContent.css";

const PageContent = ({className, ...props}) => {
  return (
    <Grid {...props} className={`sc-page-content${className && ` ${className}` || ''}`}>
        {props.children}
    </Grid>
  );
};

export default PageContent;
