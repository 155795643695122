import { Drawer } from 'rsuite';

const SCDrawer = (props) => {
    return (
        <Drawer {...props}>{props.children}</Drawer>
    )
}

SCDrawer.Header = Drawer.Header;
SCDrawer.Body = Drawer.Body;
SCDrawer.Footer = Drawer.Footer;
SCDrawer.Title = Drawer.Title;

export default SCDrawer;