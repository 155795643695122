import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from '@/Contexts/GlobalContext';
import { AuthContext } from "@/Contexts/AuthContext";
import { Page } from "@/Components/Layout";
import { Message } from "@/Components/UI";
import "./Unauthorized.css";
import {hasAuth} from "@/Helpers/AuthHelper";

const UnauthorizedPage = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Page wide>
            <Page.Header />

            <Message
                title={t("Warning")}
                description={t("You are not authorized to access this application or a part of this application")}

            />
      
        </Page>
    )
}


export default UnauthorizedPage;