import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@/Components/UI";
import { Panel } from "rsuite";

const PublicCenter = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return <>
        <Panel header={t("Looking for help with Smart City features?")} bordered>
            <p>{t("Our product knowledgebase is packed full of guides, tutorials, answers to frequently asked questions, tips for dealing with common errors, and much more.")}</p>
            <br />
            <Button appearance="outline" onClick={() => history.push("/help")}>{t("Visit the help center")}</Button>
        </Panel>
    </>
}

export default PublicCenter;