import { customersAdminUrl, customersAdminApiUrl } from "../Config/Url";
import { formatUtcToYYYY_MM_DD } from "@/Helpers/DateHelper";
import axios from "axios";


export default {
    getCustomers,
    getCustomerById,
    createCustomer,
    updateCustomer,
    getServices,
    createServiceAddress,
    updateServiceAddress,
    deleteServiceAddress,
    addPayer,
    deletePayer,
    createService,
    deleteService,
    getCustomerByOib,
    deleteCustomer
};

async function getCustomers(page, pageSize, filterValues)
{
    return axios
        .get(`${customersAdminUrl}`, { params: { pageIndex: page - 1, pageSize, searchText: filterValues?.searchText || null, ...filterValues }});
}

async function getCustomerById(id)
{
    return axios
        .get(`${customersAdminUrl}/uuid/${id}`);
}

async function createCustomer({ tenantId, customerData })
{
    return axios
        .post(`${customersAdminUrl}`, {...customerData, tenantId, messageIdentifier: 'some-unique-id'});
}

async function updateCustomer({ id, customerData })
{
    return axios
        .put(`${customersAdminUrl}/${id}`, {...customerData,
            dateOfBirth: formatUtcToYYYY_MM_DD(customerData.dateOfBirth),
            dateOfClosing: formatUtcToYYYY_MM_DD(customerData.dateOfClosing),
            dataValidFrom: formatUtcToYYYY_MM_DD(customerData.dataValidFrom),
            dataValidTo: formatUtcToYYYY_MM_DD(customerData.dataValidTo),
            dateOfEstablishment: formatUtcToYYYY_MM_DD(customerData.dateOfEstablishment),
        });
}

async function getServices(id)
{
    return axios.get(`${customersAdminApiUrl}/Service/uuid/${id}`);
}

async function createService({ customerUuid, serviceOib, serviceTitle })
{
    return axios.post(`${customersAdminApiUrl}/Vendor`, { customerUuid, serviceOib, serviceTitle });
}

async function deleteService({ serviceUuid, customerUuid })
{
    return axios.delete(`${customersAdminApiUrl}/Vendor?serviceUuid=${serviceUuid}&customerUuid=${customerUuid}`);
}

async function createServiceAddress({ serviceUuid, data })
{
    return axios.post(`${customersAdminApiUrl}/Address`, { serviceUuid, ...data });
}

async function updateServiceAddress({ addressUuid, data })
{
    return axios.put(`${customersAdminApiUrl}/Address?addressUuid=${addressUuid}`, { ...data });
}

async function deleteServiceAddress({ addressUuid, serviceUuid })
{
    return axios.delete(`${customersAdminApiUrl}/Address?addressUuid=${addressUuid}&serviceUuid=${serviceUuid}`);
}

async function addPayer({ addressUuid, userOib, userFullName })
{
    return axios.post(`${customersAdminApiUrl}/Payer`, { addressUuid, userOib, userFullName});
}

async function deletePayer({ addressUuid, userOib })
{
    return axios.delete(`${customersAdminApiUrl}/Payer?addressUuid=${addressUuid}&userOib=${userOib}`);
}

async function getCustomerByOib(oib)
{
    return axios
        .get(`${customersAdminUrl}/oib/${oib}`);
}

async function deleteCustomer(customerId)
{
    return axios.delete(`${customersAdminApiUrl}/Customer/${customerId}`);
}