import axios from "axios";
import { subscriptionsApiUrl } from "@/Config/Url";
import SubscriptionData from "../Mockups/SubscriptionData";

async function getSubscriptionInfo() {
  return { data: SubscriptionData };
  // http://localhost:5030/content/subscriptions/info
  // return axios
  //   .get(`${subscriptionsApiUrl}/content/subscriptions/info`)
  //   .then(res => res);
}

const getSubscriptionByDomainName = async domain => {
  return axios
    .get(`${subscriptionsApiUrl}/content/subscriptions/domain/${domain}`)
    .then(res => res);
};

const getSubscriptionIdByDomainName = async domain => {
  return axios.get(
    `${subscriptionsApiUrl}/content/subscriptions/subscriptionId/${domain}`,
  );
};

export default {
  getSubscriptionInfo,
  getSubscriptionByDomainName,
  getSubscriptionIdByDomainName,
};
