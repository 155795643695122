import useSWR from "swr";
import { alphaDimApiServicesUrl } from "@/Config/Url";
import { alphaDimApiUrl } from "../Config/Url";

export const useServices = () => {
  return useSWR(alphaDimApiServicesUrl);
};

export const useSourcesTenant = () => {
  return useSWR(`${alphaDimApiUrl}/api/v1/sources/tenant`);
};
