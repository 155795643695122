import useSWR from 'swr';
import { cmsApiUrl, postsUrl } from '@/Config/Url';
import { toQueryString } from "@/Helpers/UrlHelper";

export const usePost = (id) => {
    return useSWR(`${cmsApiUrl}/posts/${id}`);
}

export const useDashboard = () => {
    return useSWR(`${cmsApiUrl}/dashboard`);
}

export const useFilteredPosts = (params) => {
    const { data, error } = useSWR(`${postsUrl}/filtered?${toQueryString(params)}`);
    // console.log("Got assignable:", data)
    return {
        loading: !error && !data,
        data: data && data.results || [],
        totalData: data && data.total || 0,
        error
    };
}