import React from "react";
import {Panel} from "rsuite";
import "./Panel.css";

const SCPanel = ({className, ...props}) => {
    return (
        <Panel className={`sc-panel ${className || ''}`} {...props}>
            {props.title && <header className="sc-panel__header">{props.title}</header> || null}
            <section>
                {props.children}
            </section>
        </Panel>
    )
}

export default SCPanel;
