import { Icon } from 'rsuite';
import './Message.css';

const Message = ({ id, title, description, htmlDescription, onClose, icon }) => {

    return <div className="ui-message">
        <div className="ui-message__icon">
           {icon && <Icon icon={icon} size="3x" /> || <Icon icon="info" size="3x" />}
        </div>
        <div>
            <h4>{title}</h4>
            <p>
                {description}
            </p>
            {
                htmlDescription && (
                    <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
                )
            }
        </div>
        <div>
            {onClose &&
                <span onClick={() => onClose(id)} className="ui-message__close"><Icon icon="close" /></span>
            }
        </div>
    </div>;
}

export default Message;