/* eslint jsx-a11y/label-has-associated-control: 'off', @typescript-eslint/no-explicit-any: 'off' */
import React, { useEffect, useMemo, useState } from 'react';
import appleStock, { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { PickD3Scale, scaleTime, scaleLinear } from '@visx/scale';
import { extent } from 'd3-array';
import { Annotation, EditableAnnotation } from '@visx/annotation';


const data = appleStock.slice(-100);
const getDate = (d) => new Date(d.date).valueOf();
const getStockValue = (d) => d.close;
const annotateDatum = data[Math.floor(data.length / 2) + 4];
const approxTooltipHeight = 70;

export default function ExampleControls({
  width,
  height,
  compact = false,
  title = "Korisnici",
  subtitle = "Broj novih korisnika",
  children,
}) {
  const xScale = useMemo(
    () =>
      scaleTime({
        domain: extent(data, d => getDate(d)),
        range: [0, width],
      }),
    [width],
  );
  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: extent(data, d => getStockValue(d)),
        range: [height - 100, 100],
      }),
    [height],
  );

  const [editLabelPosition, setEditLabelPosition] = useState(false);
  const [editSubjectPosition, setEditSubjectPosition] = useState(false);
  /*
  const [title, setTitle] = useState('Title');
  const [subtitle, setSubtitle] = useState(
    compact ? 'Subtitle' : 'Subtitle with deets and deets and deets and deets',
  );*/
  const [connectorType, setConnectorType] = useState('elbow');
  const [subjectType, setSubjectType] = useState('circle');
  const [showAnchorLine, setShowAnchorLine] = useState(true);
  const [verticalAnchor, setVerticalAnchor] = useState('auto');
  const [horizontalAnchor, setHorizontalAnchor] = useState('auto');

  const [labelWidth] = useState(compact ? 100 : 175);
  const [annotationPosition, setAnnotationPosition] = useState({
    x: xScale(getDate(annotateDatum)) ?? 0,
    y: yScale(getStockValue(annotateDatum)) ?? 0,
    dx: compact ? -50 : -100,
    dy: compact ? -30 : -50,
  });
  // update annotation position when scale's change
  useEffect(() => {
    setAnnotationPosition(currPosition => ({
      ...currPosition,
      x: xScale(getDate(annotateDatum)) ?? 0,
      y: yScale(getStockValue(annotateDatum)) ?? 0,
    }));
  }, [xScale, yScale]);

  return (
    <>
      {children({
        AnnotationComponent:
          editLabelPosition || editSubjectPosition ? EditableAnnotation : Annotation,
        annotationPosition,
        approxTooltipHeight,
        connectorType,
        data,
        editLabelPosition,
        editSubjectPosition,
        getDate,
        getStockValue,
        horizontalAnchor: horizontalAnchor === 'auto' ? undefined : horizontalAnchor,
        labelWidth,
        setAnnotationPosition,
        showAnchorLine,
        subjectType,
        subtitle,
        title,
        verticalAnchor: verticalAnchor === 'auto' ? undefined : verticalAnchor,
        xScale,
        yScale,
      })}
      
      <style jsx>{`
        .controls {
          font-size: 13px;
          line-height: 1.5em;
        }
        .controls > div {
          margin-bottom: 4px;
        }
      `}</style>
    </>
  );
}
