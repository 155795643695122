import { chatApiUrl } from "@/Config/Url";
import axios from "axios";

const getChatById = async (id) => {
    let url = `/chat/${id}`;

    return axios
    .get(chatApiUrl + url, {})
    .then((res) => res);
};
  
const createMessage = async (data) => {
    let url = `/chat/${data.chatId}/messages`;
  
    return axios
      .post(chatApiUrl + url, {...data, isBackOffice: true})
      .then((res) => res);
};

const markMessagesSeen = async (chatId) => {
    const url = `/chat/${chatId}/messages/seen`;
  
    return axios
      .put(chatApiUrl + url)
      .then((res) => res);
};

const getUnreadMessages = async (chatId) => {
    let url = `/chat/${chatId}/unread`;

    return axios
    .get(chatApiUrl + url, {})
    .then((res) => res);
};

const getUnreadCount = async () => {
    let url = `/chat/unread-count`;

    return axios
    .get(chatApiUrl + url, {})
    .then((res) => res);
};
  
  
  
export default {
    getChatById,
    createMessage,
    markMessagesSeen,
    getUnreadMessages,
    getUnreadCount
};
  