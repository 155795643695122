import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, ControlLabel } from 'rsuite';
import { Button, Form, Textbox, TagPicker } from "@/Components/UI";
import './PostsFilterPopup.css';
import ClickAwayListener from "../../Components/ClickAwayListener";

const PostsFilterPopup = ({ visible, title, onChange, onSubmit, onClear, value, tags, onClickAway, ...props }) => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState(value);

    const handleFilterChange = (values) => {
        setFilterValues(values);   
        onChange && onChange(values)     
    }

    const handleClearClick = () => {
        setFilterValues();
        onClear();
    }

    const handleSubmit = () => {
        onSubmit(filterValues);
    }

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <Form formValue={filterValues} onChange={handleFilterChange}>
                <div className="filter-container box-container filled">
                    <div>
                        <Textbox title={t("Search by title")} name="titleContains" />
                    </div>
                    <div>
                        <Textbox title={t("Search by content")} name="contentContains" />
                    </div>
                    <div className="filter-container__span_columns">
                        <ControlLabel>{t("Tag")}</ControlLabel>
                        <Form.Control accepter={(props) => <TagPicker {...props} />} 
                            valueKey="name" 
                            labelKey="name" 
                            value={filterValues && filterValues.assignedTags || []} 
                            data={tags} 
                            name="assignedTags" 
                            groupBy="tagVisibilityName" 
                        />
                    </div>
                </div>
                <div className="filter-container__buttons">
                    <Button onClick={handleClearClick}>{t("Reset all")}</Button>
                    <Button appearance="trigger" onClick={handleSubmit}>{t("Apply filters")}</Button>
                </div>
            </Form>
        </ClickAwayListener>
    );
};

export default PostsFilterPopup;