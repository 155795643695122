import useSWR from 'swr';
import { notificationsApiUrl } from '@/Config/Url';

export const useInternalNotification = () => {
    return useSWR(`${notificationsApiUrl}/notifications/admin/current-user/internal`);
}

export const useActiveChannels = () => {
    return useSWR(`${notificationsApiUrl}/notifications/channels?isDeleted=false`);
}

export const useUserPreferences = (userId) => {
    return useSWR(`${notificationsApiUrl}/notifications/admin/user-preferences/${userId}`);
}

export const useUnreadNotificationCount = () => {
    return useSWR(`${notificationsApiUrl}/notifications/admin/current-user/unread-count`);
}