import { Badge } from "rsuite";
import { useTranslation } from "react-i18next";
import './StatusBadge.css';

const StatusBadge = ({ txtContent, color }) => {
    const { t } = useTranslation();

    return <>
        <Badge className={`status-badge ${color.toLowerCase()}`} content={t(txtContent)} />
    </>
}

export default StatusBadge;