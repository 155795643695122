import { Checkbox, Input, Col } from "rsuite";
import { useEffect, useState, useContext } from "react";
import {
  Modal,
  FlexboxGrid,
  Form,
  Button,
  SelectPicker,
} from "@/Components/UI";
import UserInfo from "@/Components/UserInfo";
import Tabs from "@/Components/UI/Tabs";
import { useTranslation } from "react-i18next";
import { Schema } from "rsuite";
import CustomerSelection from "@/Components/CustomerSelection/CustomerSelectionUsers";
import UserViewer from "@/Components/UserViewer/UserViewer";
import { ticketingService } from "@/Services";
import AssignablePicker from "./AssignablePicker";
import { GlobalContext } from "../../Contexts/GlobalContext";
const { StringType } = Schema.Types;

const OpenTicketEditor = ({ onSubmit, onClose, show }) => {
  const { t } = useTranslation();
  const { errorHandler } = useContext(GlobalContext);

  const [ticketData, setTicketData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [previewCustomer, setPreviewCustomer] = useState(null);
  const [showCustomerViewer, setShowCustomerViewer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [categories, setCategories] = useState([]);
  const [saving, setSaving] = useState(false);

  const ticketModelUser = Schema.Model({
    userId: StringType().isRequired(`${t("User")} ${t("is required")}`),
    title: StringType().isRequired(`${t("Title")} ${t("is required")}`),
    description: StringType().isRequired(
      `${t("Description")} ${t("is required")}`,
    ),
    preferredContact: StringType().isRequired(
      `${t("Preferred contact")} ${t("is required")}`,
    ),
    // assignTo: StringType().addRule((value, data) => {
    //     if (!value && !data.topicId) {
    //         return false;
    //     }
    //     return true;
    //     }, t('{{max}} cannot be set before {{min}}', {max: t("Valid from"), min: t("Valid to")})),
    // topicId: StringType().addRule((value, data) => {
    //     if (!value && !data.assignToId) {
    //         return false;
    //     }
    //     return true;
    //     }, t('{{max}} cannot be set before {{min}}', {max: t("Valid from"), min: t("Valid to")}))
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesRes] = await Promise.all([
          ticketingService.getCategories(),
        ]);
        if (categoriesRes && categoriesRes.data) {
          setCategories(categoriesRes.data);
        }
      } catch (error) {
        console.error("Error while getting ticketing categories", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const customer = selectedCustomer || {};
    const saveData = { ...ticketData, ...customer };
    const checkResult = ticketModelUser.check(saveData);
    setErrors(checkResult);

    if (!saveData.assignedToId && !saveData.topicId) {
      setErrors(old => ({
        ...old,
        assignedToId: {
          hasError: true,
          errorMessage: t(
            "You need to select a ticket topic or an organization to assign the ticket to",
          ),
        },
        topicId: {
          hasError: true,
          errorMessage: t(
            "You need to select a ticket topic or an organization to assign the ticket to",
          ),
        },
      }));
    }
  }, [ticketData, selectedCustomer]);

  const toggleCustomerViewer = () => {
    setShowCustomerViewer(!showCustomerViewer);
  };

  const handlePreviewCustomer = customer => {
    setPreviewCustomer(customer);
    toggleCustomerViewer();
  };

  const handleSelectCustomer = customer => {
    setSelectedCustomer({
      ...customer,
      displayName: `${customer.firstName} ${customer.lastName}`,
    });
    // const checkResult = ticketModelUser.check({...ticketData, ...customer})
    // setErrors(checkResult)
    toggleCustomerViewer();
  };

  const clearCustomer = () => {
    setPreviewCustomer(null);
    setSelectedCustomer(null);
    // const checkResult = ticketModelUser.check({...ticketData, userId: null})
    // setErrors(checkResult)
  };

  const handleClose = () => {
    clearCustomer();
    onClose();
  };

  const handleAssignByChange = tabId => {
    setTicketData(old => ({
      ...old,
      assignedToId: null,
      topicId: null,
      categoryId: null,
    }));
  };

  const handleSubmit = async () => {
    setErrors();
    const saveData = {
      ...ticketData,
      userId: selectedCustomer?.id,
      ...selectedCustomer,
      userExternalId: selectedCustomer?.oib,
      userFirstName: selectedCustomer?.firstName,
      userLastName: selectedCustomer?.lastName,
      userEmail: selectedCustomer?.email,
      userPhoneNumber: selectedCustomer?.phoneNumber,
    };

    const checkResult = ticketModelUser.check(saveData);
    setErrors(checkResult);

    if (!saveData.assignedToId && !saveData.topicId) {
      setErrors(old => ({
        ...old,
        assignedToId: {
          hasError: true,
          errorMessage: t(
            "You need to select a ticket topic or an organization to assign the ticket to",
          ),
        },
        topicId: {
          hasError: true,
          errorMessage: t(
            "You need to select a ticket topic or an organization to assign the ticket to",
          ),
        },
      }));
      return;
    }

    if (saveData?.preferredContact) {
      if (ticketData.preferredContact == "Phone" && !saveData.phoneNumber) {
        alert(
          t(
            "User has no phone number so it can't be the preferred contact method",
          ),
        );
        return;
      }

      if (saveData.preferredContact == "Email" && !saveData.email) {
        alert(
          t("User has no email so it can't be the preferred contact method"),
        );
        return;
      }
    }

    if (Object.entries(checkResult).every(t => !t[1].hasError)) {
      try {
        setSaving(true);
        await ticketingService.createTicket(saveData);
        onSubmit(saveData);
        handleClose();
      } catch (error) {
        console.error("Error while creating ticket in backoffice", error);
        errorHandler(error);
      } finally {
        setSaving(false);
      }
    }
  };

  const handleAddNewUser = async () => {
    setPreviewCustomer();
    toggleCustomerViewer();
  };

  return (
    <>
      <Modal
        id="openTicketModal"
        backdrop="static"
        size="lg"
        show={show}
        onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("Open ticket")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            model={ticketModelUser}
            formValue={ticketData}
            onChange={setTicketData}
            fluid>
            <FlexboxGrid>
              <FlexboxGrid.Item
                componentClass={Col}
                sm={24}
                style={{ marginBottom: "2rem" }}>
                <Form.Label>{t("User")}</Form.Label>
                {(!selectedCustomer && (
                  <CustomerSelection
                    onSelect={handlePreviewCustomer}
                    onAddUser={handleAddNewUser}
                  />
                )) ||
                  null}

                {(errors && errors["userId"]?.hasError && (
                  <span style={{ color: "red", marginTop: 0 }}>
                    {errors["userId"]?.errorMessage}
                  </span>
                )) ||
                  null}
                {(selectedCustomer && (
                  <>
                    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      <UserInfo user={selectedCustomer} />
                      <Button
                        style={{ marginTop: "1rem" }}
                        onClick={clearCustomer}>
                        {t("Choose a different customer")}
                      </Button>
                    </div>
                    <Form.Label>
                      {t("User prefers to be contacted via")}
                    </Form.Label>
                    <Form.Control
                      accepter={props => (
                        <SelectPicker cleanable={true} {...props} />
                      )}
                      valueKey="value"
                      labelKey="text"
                      required
                      data={[
                        { value: "Phone", text: t("Phone") },
                        { value: "Email", text: t("Email") },
                      ].filter(
                        d =>
                          !selectedCustomer ||
                          (selectedCustomer?.phoneNumber &&
                            d.value == "Phone") ||
                          (selectedCustomer?.email && d.value == "Email"),
                      )}
                      name="preferredContact"
                      errors={errors}
                    />
                  </>
                )) ||
                  null}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item
                componentClass={Col}
                sm={24}
                style={{ marginBottom: 0 }}>
                <Form.Label>{t("Assign ticket by")}:</Form.Label>
                <Tabs style={{ width: "100%" }} onChange={handleAssignByChange}>
                  <div name={t("Topic")}>
                    <FlexboxGrid.Item componentClass={Col} sm={24}>
                      <Form.Label>{t("Category")}</Form.Label>
                      <Form.Control
                        accepter={props => (
                          <SelectPicker cleanable={true} {...props} />
                        )}
                        valueKey="id"
                        labelKey="title"
                        placeholder={t("Choose a category")}
                        data={categories}
                        name="categoryId"
                        errors={errors}
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} sm={24}>
                      <Form.Label>{t("Topic")}</Form.Label>
                      <Form.Control
                        accepter={props => (
                          <SelectPicker cleanable={true} {...props} />
                        )}
                        valueKey="id"
                        labelKey="title"
                        placeholder={t("Choose a topic")}
                        data={
                          categories.find(c => c.id == ticketData?.categoryId)
                            ?.children || []
                        }
                        name="topicId"
                        errors={errors}
                      />
                    </FlexboxGrid.Item>
                  </div>
                  <div name={t("Organization")}>
                    <FlexboxGrid.Item componentClass={Col} sm={24}>
                      <Form.Group className="topic-info__description">
                        <Form.Label> {t("Assign to organization")}</Form.Label>
                        <Form.Control
                          accepter={props => (
                            <AssignablePicker
                              block
                              display="Organization"
                              cleanable={true}
                              {...props}
                            />
                          )}
                          placeholder={t(
                            "Organization to assign the ticket to",
                          )}
                          name="assignedToId"
                          errors={errors}
                        />
                      </Form.Group>
                    </FlexboxGrid.Item>
                  </div>
                </Tabs>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item componentClass={Col} sm={24}>
                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  name="title"
                  errors={errors}
                  componentClass="input"
                />
                <Form.HelpText>{t("Required")}</Form.HelpText>
                <Form.Label>{t("Description")}</Form.Label>
                <Form.Control
                  name="description"
                  errors={errors}
                  rows={5}
                  componentClass="textarea"
                />
                <Form.HelpText>{t("Required")}</Form.HelpText>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>

        <Modal.Footer
          style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Button loading={saving} onClick={handleSubmit} appearance="primary">
            {t("Open")}
          </Button>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
        </Modal.Footer>
      </Modal>

      <UserViewer
        show={showCustomerViewer}
        oib={previewCustomer?.oib}
        onSelect={handleSelectCustomer}
        onClose={toggleCustomerViewer}
      />
    </>
  );
};

export default OpenTicketEditor;
