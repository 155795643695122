import React, { useContext, useEffect } from "react";
import { Container, Content } from 'rsuite';
import Sidebar from './Sidebar';
import { Notification } from '@/Components/UI';
import { GlobalContext } from "@/Contexts/GlobalContext";
import Loader from "../Loader";
import "./Layout.css";
import { useWindowDimension } from "../../Hooks/resizeHooks";

const Layout = ({ hideSidebar, ...props }) => {
  const [width, height] = useWindowDimension();
  const { loading, darkMode } = useContext(GlobalContext);
  const [expanded, setExpanded] = React.useState(true)
  const toggleSidebar = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    setExpanded(width > 1280)
  }, [width])

  useEffect(() => {
    const root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)


    if(darkMode) {
      root.setAttribute( 'class', 'dark' );
    }
    else {
      root.removeAttribute( 'class', 'dark' );
    }
  
  }, [darkMode])

  return (
    <>
    <div className={`sc-one-app`}>
      <div className="sc-viewport">
        <Container>
          {!hideSidebar && (
            <Sidebar expanded={expanded} toggleSidebar={toggleSidebar} />
          ) || null}

          <Container className="sc-main">
            <Content>
              {props.children}
            </Content>
          </Container>
        </Container>
      
      </div>
     
    </div>
    {loading && <Loader /> || null}
    </>
  );
};

export default Layout;
